
import { User, getAuth, onAuthStateChanged, signInWithPopup, GoogleAuthProvider, signOut } from "firebase/auth";
import { useEffect, useState } from "react";

export type { User }

export function useFirebaseAuth() {
  const auth = getAuth();
  const provider = new GoogleAuthProvider();
  const chacedUser = window.sessionStorage.getItem('firebase-cached-user')
  const [user, setUserState] = useState<User | string | null | undefined>(chacedUser)

  const setUser = (u: User | null | undefined) => {
    if (u && u.displayName){
      window.sessionStorage.setItem('firebase-cached-user', u.displayName)
    }
    setUserState(u)
  }

  useEffect(() => {
    onAuthStateChanged(auth, (loggedInUser) => {
      setUser(loggedInUser)
    });
  })

  const doSignIn = () => {
    signInWithPopup(auth, provider).then((result) => {
      // This gives you a Google Access Token. You can use it to access the Google API.
      const credential = GoogleAuthProvider.credentialFromResult(result);
      if (!credential) return
      // const token = credential.accessToken;
      // The signed-in user info.
      setUser(result.user);
      // ...
    }).catch((error) => {
      console.error(error)
      // // Handle Errors here.
      // const errorCode = error.code;
      // const errorMessage = error.message;
      // // The email of the user's account used.
      // const email = error.email;
      // // The AuthCredential type that was used.
      // const credential = GoogleAuthProvider.credentialFromError(error);
      // // ...
    });
  }

  const doSignOut = () => {
    signOut(auth)
  }

  return {
    user,
    doSignIn,
    doSignOut
  }
}