import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import './ListDocuments.scss'
import {plans as rawPlans} from './plans'
import { useFirebaseAuth } from "./react-use-firebase-auth";

const builtInPlans = rawPlans.filter(x => x.length > 0).map(plan => plan.replace(/.json$/, ''))
const PREFIX = 'jsan:'
const PLAN_PREFIX = 'jplan:'

function pickNewName(planType:string, plan: string) {
  const localDocuments = readDocumentsFromLocalStorage()
  const planName = `${planType}:${plan}`
  console.log('localDocuments:', localDocuments, planName)
  console.log('localDocuments[planName]:', localDocuments[planName])
  let n = 0
  let name
  do {
    n++
    name = `new-${plan}-${n}`
  } while((localDocuments[planName]||[]).includes(name))
  return name
}

// interface InputChangeDef {
//   value: string
//   onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
// }

// function useInputState(def: string | (() => string)): [string, (val: string) => void, InputChangeDef] {
//   const [value, setValue] = useState(def)

//   return [value, setValue, {value, onChange: e => setValue(e.target.value)}]
// }

function readDocumentsFromLocalStorage(): {[key: string]: string[]} {
  const values = [...new Array(window.localStorage.length)]
    .map((_,i) => window.localStorage.key(i))
    .map(key => key && key.startsWith(PREFIX) ? key.substr(PREFIX.length) : null)
    .filter(notNull)
    .reduce((acc: {[key: string]:string[]}, key: string) => {
      const [loc, name, doc] = key.split(':')
      if (loc.length > 1) return acc
      const plan = `${loc}:${name}`
      acc[plan] = [...(acc[plan] || []), doc]
      return acc
    }, {});

  return values
}

function readPlansFromLocalStorage(): string[] {
  const values = [...new Array(window.localStorage.length)]
    .map((_,i) => window.localStorage.key(i))
    .map(key => key && key.startsWith(PLAN_PREFIX) ? key.substr(PLAN_PREFIX.length) : null)
    .filter(notNull)
    // .reduce((acc: {[key: string]:string[]}, key: string) => {
    //   const [loc, name, doc] = key.split(':')
    //   if (loc.length > 1) return acc
    //   const plan = `${loc}:${name}`
    //   acc[plan] = [...(acc[plan] || []), doc]
    //   return acc
    // }, {});

  return values
}

interface ListDocumentsProps {x?:string}

export function ListDocuments({x}: ListDocumentsProps) {
  const {user, doSignIn, doSignOut} = useFirebaseAuth()

  const [localDocumentsFor, setLocalDocumentsFor] = useState<{[key: string]: string[]}>()
  const [localPlans, setLocalPlans] = useState<string[]>([])

  const reloadDocs = () => setLocalDocumentsFor(readDocumentsFromLocalStorage())

  useEffect(() => {
    reloadDocs()
    setLocalPlans(readPlansFromLocalStorage())
  }, [])

  const createLocalPlan = () => {

  }

  if (!localDocumentsFor) return <div>Loading...</div>

  return <div className="ListDocuments">
    <div>
      <h1>JSAN Editor</h1>
      {user === null && <button onClick={() => doSignIn()}>Login</button> }
      {typeof user === 'string' && <span>Hello {user} <button onClick={() => doSignOut()}>Logout</button> </span>}
      {user && typeof user === 'object' && <span>Hello {user.displayName} <button onClick={() => doSignOut()}>Logout</button> </span>}
    </div>
    {/* <h2>Built in</h2> */}
    {builtInPlans.map(plan => <ListDocsForPlan key={plan} prefix='b' localDocuments={localDocumentsFor[`b:${plan}`] || []}  {...{plan, reloadDocs}}  />)}

    <div style={{display: 'none'}}>
      <h2>Local</h2>
      <button onClick={() => createLocalPlan()} >Create local plan</button>
      {localPlans.map(plan => <ListDocsForPlan key={plan} prefix='l' localDocuments={localDocumentsFor[`l:${plan}`] || []}  {...{plan, reloadDocs}}  />)}
    </div>
  </div>
}

function notNull<T>(value: T | null): value is T {
  return value !== null
}

interface ListDocsForPlanProps {
  prefix: 'b' | 'l'
  plan: string
  localDocuments: string[]
  reloadDocs: () => void
}
function ListDocsForPlan({prefix, plan, localDocuments, reloadDocs}:ListDocsForPlanProps) {
  // const [newDocName, setNewDocName, newDocNameInput] = useInputState(() => pickNewName(plan))
  //const [newDocName, setNewDocName] = useInputState(() => pickNewName(plan))
  const createDocument = () => {
    const newDocName = pickNewName('b', plan)
    const newKey = `${PREFIX}${prefix}:${plan}:${newDocName}`
    if (!window.localStorage.getItem(newKey)) {
      window.localStorage.setItem(newKey, JSON.stringify({created: new Date()}))

      reloadDocs()
    }
  }

  const removeDocument = (name: string) => {
    const key = `${PREFIX}${prefix}:${plan}:${name}`
    window.localStorage.removeItem(key)

    reloadDocs()
  }

  return <div className='ListDocsForPlan'>
    <h2><Link to={`/plan/${prefix}:${plan}`}>{plan}</Link></h2>
    
    <button onClick={() => createDocument()}>Create</button>
    
    <details className='localDocuments'>
      <summary>Documents ({localDocuments.length})</summary>
      {localDocuments.map(name => <React.Fragment key={name}>
        <div>
          <Link to={`/plan/${prefix}:${plan}/document/${name}`}>{name}</Link>
          <button title="delete" onClick={() => removeDocument(name)}>✕</button>
        </div>
      </React.Fragment>)}
        
    </details>
    {/* <div>
      <input {...newDocNameInput} />
      
    </div> */}
    </div>
}
