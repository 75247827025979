import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"

const PREFIX = 'plan-'

interface ListPlansProps {x?:string}
export function ListPlans({x}: ListPlansProps) {
  if (x) console.log('x')
  const [localPlans, setLocalPlans] = useState<string[]>([])

  useEffect(() => {
    const keys = [...new Array(window.localStorage.length)]
      .map((_,i) => window.localStorage.key(i))
      .map(key => key && key.startsWith(PREFIX) ? key.substr(PREFIX.length) : null)
      .filter(notNull)
    setLocalPlans(keys)
  }, [])

  return <div className="ListPlans">
    <h1>List Plans</h1>
    <h2>Local plans</h2>
    <ul>
      {localPlans.map(key => <ul key={key}><Link to={`/edit-plan/${key}`}>{key}</Link></ul>)}
    </ul>
  </div>
}

function notNull<T>(value: T | null): value is T {
  return value !== null
}