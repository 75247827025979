
const MAX_LENGTH = 80

export function prettyFormat(obj: any): string {
  //return JSON.stringify(obj)
  return format(obj, {indent: '', left: 0})
}

interface Context {
  indent: string
  left: number
}

function format(obj: any, context: Context) {
  switch(typeof obj) {
    case 'object': return formatObject(obj, context)
    default: return formatValue(obj)
  }
}

function formatValue(val: number | string | boolean) {
  return JSON.stringify(val)
}

function formatObject(obj: any, context: Context): string {
  if(obj === undefined) return ''
  if(obj === null) return 'null'
  if(Array.isArray(obj)) {
    return formatArray(obj, context)
  }

  const inline = formatObjectInline(obj, context.left)
  if (inline) return inline

  const entries = Object.entries(obj)
  // return "{" + entries.map(([k,v]) => {
  //   return `"${k}":${format(v, context)}`
  // }).join(',\n') + "}"

  return `{\n` +
  entries.map(([k,v]) => {
    const pre = `${context.indent + '  '}"${k}": `
    return `${pre}${format(v, {...context, left: pre.length, indent: context.indent+'  '})}`
  }).join(',\n') + 
  `\n${context.indent}}\n`
}



function formatArray(arr: any[], context: Context): string {
  // const types = arr.map(item => typeof item)
  // if (['number', 'string'].includes(types[0]) && types.every((t, _, tarr) => t === tarr[0])) {
  //   const line = `[${arr.map(formatValue).join(', ')}]`
  //   if (line.length + context.left < MAX_LENGTH) {
  //     return line
  //   }
  // }
  
  const inline = formatInline(arr, context.left)
  if (inline) return inline

  return `[\n` +
    arr.map((v) => {
      const pre = `${context.indent + '  '} `
      return pre + format(v, {...context, left: pre.length, indent: context.indent+'  '})
    }).join(',\n') + 
    `\n${context.indent}]`
}

function formatInline(obj: any, preLen: number) {
  switch(typeof obj) {
    case 'string': 
    case 'number': 
    case 'boolean':
      return formatValueInline(obj, preLen)
    case 'object':
      return formatObjectTypeInline(obj, preLen)
  }
}

function formatObjectTypeInline(obj: object | any[] | null | undefined, preLen: number): string | undefined {
  if (obj === null) return formatValueInline(obj, preLen)
  if (obj === undefined) throw new Error('undefined')
  if (Array.isArray(obj)) return formatArrayInline(obj, preLen)
  return formatObjectInline(obj, preLen)
}

function formatValueInline(val: number | string | boolean | null, preLen: number): string | undefined {
  const rep = JSON.stringify(val)
  if (preLen + rep.length < MAX_LENGTH) return rep
  else return undefined
}

function formatArrayInline(arr: any[], preLen: number): string | undefined {
  const items = []
  let preLenInc = preLen + '{}'.length
  for(let item of arr) {
    if (preLenInc > MAX_LENGTH) return undefined
    const inline = formatInline(item, preLenInc)
    if (!inline) return undefined
    preLenInc += inline.length + 2
    items.push(inline)
  }
  return `[${items.join(', ')}]`
}
function formatObjectInline(obj: object, preLen: number): string | undefined { 
  const items = []
  let preLenInc = preLen + '{}'.length
  for(let [key, item] of Object.entries(obj)) {
    if (preLenInc > MAX_LENGTH) return undefined
    const keyRep = JSON.stringify(key)+': '
    preLenInc += keyRep.length
    const inline = formatInline(item, preLenInc)
    if (!inline) return undefined
    preLenInc += inline.length + 2
    items.push(keyRep+inline)
  }
  return `{${items.join(', ')}}`
 }