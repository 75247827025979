import React, { useState, useEffect } from 'react';
import { EditorPanel } from './EditorPanel';
import { OutputPanel } from './OutputPanel';
import { Observer, Processor } from './processor';
import './Quadrant.scss'

export type QuadrantProps = {
  location: string
  processor: Processor
}

export function Quadrant({ location, processor }: QuadrantProps) {
  const config = processor.config
  const editors = !config.editors ? [] : Object.entries(config.editors).filter(([, v]) => v.location === location);
  const views = !config.views ? [] : Object.entries(config.views).filter(([, v]) => v.location === location);

  const [visibleTab, setVisibleTabState] = useState(processor.selectedTabs[location] || editors?.[0]?.[0] || views?.[0]?.[0] || '');

  const setVisibleTab = (newTab: string) => {
    setVisibleTabState(newTab)
    processor.saveSelectedTab(location, newTab)
  }

  const selectedView = views.find(([name]) => name === visibleTab)

  return <div className={`Quadrant ${location}`}>
    <div className='top'>
      <div className='tabs'>
        {editors.map(([name]) => <button key={name} className={name === visibleTab?'selected':''} onClick={() => setVisibleTab(name)}>{name}</button>)}
        {views.map(([name]) => <button key={name} className={name === visibleTab?'selected':''} onClick={() => setVisibleTab(name)}>{name}</button>)}
      </div>
      <div className='utility'>
        {selectedView && <CopyButton bind={selectedView[1].bind} processor={processor}/>}
      </div>
    </div>
    
    <div className="main">
      {editors.map(([name, interfaceConfig]) => <EditorPanel key={name} show={name === visibleTab} bind={`e:${name}`} {...{ name, interfaceConfig, processor }} />)}
      {views.map(([name, interfaceConfig]) => <OutputPanel key={name} show={name === visibleTab} bind={interfaceConfig.bind} {...{ name, interfaceConfig, processor }} />)}
    </div>
  </div>;
}


interface CopyButtonProps {bind: string, processor: Processor}
export function CopyButton({bind, processor}: CopyButtonProps) {
  const [text, setText] = useState('');
  const [done, setDone] = useState(false)
  useEffect(() => {
    const ob: Observer = (value, _ ,error) => {
      if (!error) {
        setText(value);
      }
    };

    const intialValue = processor.subscribeTo(bind, ob);
    if (intialValue) {
      setText(intialValue);
    }

    return () => {
      processor.unsubscribeFrom(bind, ob);
    };
  }, [processor, bind]);

  const doCopy = async () => {
    await navigator.clipboard.writeText(text)
    setDone(true)
    setTimeout(() => setDone(false), 3000)
  }


  return <button className="CopyButton" title="copy" disabled={!text} onClick={() => doCopy()}>{done ? '✔️' : '⎘'} Copy</button>
}