import { bm } from "./BetterMap";
import { Observer, Processor } from "./processor";
import { BetterMap } from "./BetterMap";
import { PipelineStepConfig } from "./types";
import { parseJSON } from "./json-parser";
import { prettyFormat } from "./prettyFormat";

type ProcessOutput =  {valid: true, value: string} | {valid: false, error: string}

function toError(error: string): ProcessOutput {
  return {valid: false, error}
}

function toValue(value: string): ProcessOutput {
  return {valid: true, value}
}

export class PipelineStep {
  currentValue?: string;
  error?: string;
  inputValue?: string;
  observers: Observer[];
  config: PipelineStepConfig;
  process: (value: string) => ProcessOutput;
  sourceHandle?: Observer;
  otherHandles?: BetterMap<Observer>;
  otherValues: BetterMap<string>;

  constructor(config: PipelineStepConfig) {
    this.config = { ...config };
    this.observers = [];
    this.otherValues = new BetterMap();

    switch (config.process) {
      case 'format': this.process = this.format; break;
      case 'format-pretty': this.process = this.formatPretty; break;
      case 'format-jsonlint': this.process = this.formatJsonlint; break;
      case 'jsToJson': this.process = this.jsToJson; break;
      case 'run-data': this.process = this.runData; break;
      case 'run-json': this.process = this.runJson; break;
      case 'repl': this.process = this.repl; break;
      case 'arrToLines': this.process = this.arrToLines; break;
      case 'makeHtml': this.process = this.makeHtml; break;
      default: throw new Error(`Currently ${config.process} not supported`);
    }
  }

  

  arrToLines(value: string): ProcessOutput {
    const arr = JSON.parse(value);
    if (!Array.isArray(arr)){
      return toError('Input not an array');
    } else {
      return toValue(arr.map(el => `${el}`).join('\n'))
    }
  }

  repl(value: string): ProcessOutput {
    try {

      // eslint-disable-next-line
      const f = new Function(`
        const __result = [];
        console.log = (str, ...args) => __result.push(str + args.map(arg => ' ' + arg).join(''))
        ${value}
        return JSON.stringify(__result, null, '  ')
      `);

      const res = f();
      return toValue(typeof res === 'string' ? res : JSON.stringify(res, null, '  '))
    } catch (e) {
      return toError('repl error ' + e)
    }
  }
  runData(value: string): ProcessOutput {
    try {

      const code = this.otherValues.get('code');
      if (!code)
        return toError('no code')
      // eslint-disable-next-line
      const f = new Function('data', code);
      const res = f(value);
      return toValue( typeof res === 'string' ? res : JSON.stringify(res, null, '  '))
    } catch (e) {
      return toError('run error ' + e)
    }
  }
  runJson(inputValue: string): ProcessOutput {
    try {
      let json;
      try {
        json = JSON.parse(inputValue);
      } catch (pe) {
        return toError('run parse error ' + pe + inputValue)
      }
      const code = this.otherValues.get('code');
      if (!code)
        return toError('no code')
      // eslint-disable-next-line
      const f = new Function('json', code);
      const res = f(json);
      const value = typeof res === 'string' ? res : JSON.stringify(res, null, '  ');
      return toValue(value)
    } catch (e) {
      return toError('run error ' + e)
    }
  }
  makeHtml(body: string): ProcessOutput {
    try {
      const css = this.otherValues.get('css');
      const js = this.otherValues.get('js');
      const page = `
<html>
  ${css ? `<style>\n${css}\n</style>\n` : ''}
  <body>${body}</body>
  ${js ? `<script>\n${js}\n</script>\n` : ''}
</html>`
       
      return toValue(page)
    } catch (e) {
      return toError('makeHtml error ' + e)
    }
  }
  format(inputValue: string): ProcessOutput {
    try {
      const value = JSON.stringify(JSON.parse(inputValue), null, '  ');
      return toValue(value)
    } catch (e) {
      return toError('format error: ' + e)
    }
  }
  formatPretty(inputValue: string): ProcessOutput {
    try {
      const value = prettyFormat(JSON.parse(inputValue));
      return toValue(value)
    } catch (e) {
      return toError('format error: ' + e)
    }
  }
  formatJsonlint(inputValue: string): ProcessOutput {
    try {
      const value = JSON.stringify(parseJSON(inputValue), null, '  ');
      return toValue(value)
    } catch (e) {
      return  toError('format error: ' + e)
    }
  }
  jsToJson(value: string): ProcessOutput {
    try {
      // eslint-disable-next-line
      const f = new Function(`return ${value}`);
      return toValue(JSON.stringify(f()))
    } catch (e) {
      return toError('format error')
    }
  }

  activate(processor: Processor) {
    this.sourceHandle = (inputValue) => {
      this.inputValue = inputValue;
      const old = this.currentValue;
      const result = this.process(inputValue);

      if (result.valid) {
        this.currentValue = result.value;
        this.observers.forEach(ob => ob(result.value, old));
      } else {
        this.error = result.error
        this.observers.forEach(ob => ob(old || 'No valid old', old, result.error));
      }
    };

    processor.subscribeTo(this.config.source, this.sourceHandle);

    if (this.config.other) {
      this.otherHandles = bm(this.config.other).map((source, name) => {
        const handle = (inputValue: string) => {
          this.otherValues.set(name, inputValue);

          const old = this.currentValue;
          const result = this.process(this.inputValue || '');
          if (result.valid) {
            this.currentValue = result.value;
            this.observers.forEach(ob => ob(result.value, old));
          } else {
            this.error = result.error
            this.observers.forEach(ob => ob(old || 'No valid old', old, result.error));
          }
        };
        processor.subscribeTo(source, handle);
        return handle;
      });
    }
  }

  deactivate(processor: Processor) {
    if (this.sourceHandle)
      processor.unsubscribeFrom(this.config.source, this.sourceHandle);
    const { other } = this.config;
    if (this.otherHandles && other)
      this.otherHandles.forEach((handle, name) => {
        const source = other[name];
        processor.unsubscribeFrom(source, handle);
      });
  }

  addObserver(ob: Observer) {
    this.observers = [...this.observers, ob];
  }

  removeObserver(ob: Observer) {
    this.observers = this.observers.filter(x => x !== ob);
  }
}
