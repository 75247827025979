export const plans =
[
  "def.json",
  "js-to-json.json",
  "json-edit.json",
  "pretty-json.json",
  "repl.json",
  "text-edit.json",
  "web.json",
  ""
]
