/**
 * Pure JS JSON Parser
 * 
 * @see https://lihautan.com/json-parser-with-javascript/
 * @param {string} str 
 */
 export function parseJSON(str: string) {
	let i = 0;
  let col = 1;
  let line = 1;
  function inc() {
    i++
    col++
  }

  
  

	const value = parseValue();
	expectEndOfInput();
	return value;

	function parseObject() {
		if (str[i] === '{') {
			inc();
			skipWhitespace();

			const result: {[key: string]: any} = {}
			
			let initial = true;
			// if it is not '}',
			// we take the path of string -> whitespace -> ':' -> value -> ...
			while (str[i] !== '}') {
				if ( ! initial) {
					eatChar(',');
					skipWhitespace();
				}
				const key = parseString();
				if (key === undefined) {
					return expectObjectKey();
				}
				skipWhitespace();
				eatChar(':');
				const value = parseValue();
				result[key] = value;
				initial = false;
			}
			
			// move the the next character of '}'
			inc();

			return result;
		}
	}

	function parseArray() {
		if (str[i] === '[') {
			inc();
			skipWhitespace();

			const result = [];
			let initial = true;
			while (str[i] !== ']') {
				if ( ! initial) {
					eatChar(',');
				}
				const value = parseValue();
				result.push(value);
				initial = false;
			}

			expectNotEndOfInput("]");
			// move to the next character of ']'
			inc();
			return result;
		}
	}

	function parseValue() {
		skipWhitespace();

		// The 'native' but not yet well supported way...
		// Null only works because it's the last value checked for
		/* const value = parseString() ??
			parseNumber() ??
			parseObject() ??
			parseArray() ??
			parseKeyword('true', true) ??
			parseKeyword('false', false) ??
			parseKeyword('null', null); */

		// A compromise that is better supported
    type TryFn = () => void
		const tryFn = (...fns: TryFn[]) => {
			// find the first fn that doesn't return undefined
			for (let n = 0; n < fns.length; n++) {
				const fn = fns[n];
				const res = fn();
				
				if (res !== undefined) {
					return res;
				}
			}
		}

		const value = tryFn(
			parseString,
			parseNumber,
			parseObject,
			parseArray,
			parseKeyword('true', true),
			parseKeyword('false', false),
			parseKeyword('null', null)
		);

		skipWhitespace();
		
		return value;
	}

	function parseKeyword(name: string, value: boolean | null) {
		return () => {
      if (str.slice(i, i + name.length) === name) {
			  i += name.length;
			  return value;
		  }
    }
	}

	function skipWhitespace() {
		while ([' ', "\n", "\t", "\r"].includes(str[i])) {
			if (str[i] === "\n") {
        i++
        line++
        col = 1
      } else {
        inc()
      }
		}
	}

	function parseString() {
		if (str[i] === '"') {
			inc();
			let result = "";
			while (i < str.length && str[i] !== '"') {
				if (str[i] === "\\") {
					const char = str[i + 1];
					if (['"', "\\", "/"].includes(char)) {
						result += char;
						inc();
					} else if (['b', 'f', 'n', 'r', 't'].includes(char)) {
						// Non-visible characters need to get parsed as 
						// their escape sequence, not the letter of 
						// the escape sequence.
						const replacement: {[key: string]: string} = {
							b: "\b",
							f: "\f",
							n: "\n",
							r: "\r",
							t: "\t",
						} as const;

						result += replacement[char];
						inc();
					} else if (char === "u") {
						if (
							isHexadecimal(str[i + 2]) &&
							isHexadecimal(str[i + 3]) &&
							isHexadecimal(str[i + 4]) &&
							isHexadecimal(str[i + 5])
						) {
							result += String.fromCharCode(
								parseInt(str.slice(i + 2, i + 6), 16)
							);
							i += 5;
						} else {
							i += 2;
							expectEscapeUnicode(result);
						}
					} else {
						expectEscapeCharacter(result);
					}
				} else {
					result += str[i];
				}
				inc();
			}
			expectNotEndOfInput('"');
			inc();
			return result;
		}
	}

	function isHexadecimal(char: string) {
		return /[0-9a-f]/i.test(char);
	}

	function parseNumber() {
		let start = i;
		if (str[i] === "-") {
			inc();
			expectDigit(str.slice(start, i));
		}
		if (str[i] === "0") {
			inc();
		} else if (str[i] >= "1" && str[i] <="9") {
			inc();
			while (str[i] >= "0" && str[i] <="9") {
				inc();
			}
		}

		if (str[i] === ".") {
			inc();
			expectDigit(str.slice(start, i));
			while (str[i] >= "0" && str[i] <= "9") {
				inc();
			}
		}
		if (str[i] === "e" || str[i] === "E") {
			inc();
			if (str[i] === "-" || str[i] === "+") {
				inc();
			}
			expectDigit(str.slice(start, i));
			while (str[i] >= "0" && str[i] <= "9") {
				inc();
			}
		}

		if (i > start) {
			return Number(str.slice(start, i));
		}
	}

	function eatChar(char: string) {
		if (str[i] !== char) {
			throw new Error(`Expected "${char}".`);
		}
		inc();
	}

	function expectNotEndOfInput(expected: string) {
		if (i === str.length) {
			printCodeSnippet(`Expecting a \`${expected}\` here`);
			throw new Error("JSON_ERROR_0001 Unexpected End of Input");
		}
	}

	function expectEndOfInput() {
		if (i < str.length) {
			printCodeSnippet("Expected to end here");
			throw new Error("JSON_ERROR_0002 Expected End of Input");
		}
	}

	function expectObjectKey() {
		printCodeSnippet(`Expecting object key here

For example:
{ "foo": "bar" }
  ^^^^^`);
		throw new Error("JSON_ERROR_0003 Expecting JSON Key");
	}

	// function expectCharacter(expected: string) {
	// 	if (str[i] !== expected) {
	// 		printCodeSnippet(`Expecting a \`${expected}\` here`);
	// 		throw new Error("JSON_ERROR_0004 Unexpected token");
	// 	}
	// }

	function expectDigit(numSoFar: string) {
		if (!(str[i] >= "0" && str[i] <= "9")) {
			printCodeSnippet(`JSON_ERROR_0005 Expecting a digit here
	
For example:
${numSoFar}5
${" ".repeat(numSoFar.length)}^`);
		throw new Error("JSON_ERROR_0006 Expecting a digit");
		}
	}

	function expectEscapeCharacter(strSoFar: string) {
		printCodeSnippet(`JSON_ERROR_0007 Expecting escape character
	
For example:
"${strSoFar}\\n"
${" ".repeat(strSoFar.length + 1)}^^
List of escape characters are: \\", \\\\, \\/, \\b, \\f, \\n, \\r, \\t, \\u`);
		throw new Error("JSON_ERROR_0008 Expecting an escape character");
	}
	
	function expectEscapeUnicode(strSoFar: string) {
		printCodeSnippet(`Expect escape unicode
	
For example:
"${strSoFar}\\u0123
${" ".repeat(strSoFar.length + 1)}^^^^^^`);
		throw new Error("JSON_ERROR_0009 Expecting an escape unicode");
	}

	function printCodeSnippet(message: string) {
    throw new Error(`Line: ${line}, col: ${col} ${message}`)
	}
}

