import { useState } from "react"
import { useParams } from "react-router"
import { ResiazableQuads } from "../comp/ResizeableQuads"
import Editor from "@monaco-editor/react";
import { schema } from '../PlanConfig.json'
// import { PlanConfig } from "../processor/types"

import './PlanEditor.scss'
import { plans } from "../plans";
const PLAN_PREFIX = 'jplan:'


// let lastId = 0
// function nextId() {
//   lastId = lastId + 1
//   return lastId
// }

// interface Editable<T> {
//   name: string
//   config: T
//   id: number
// }
// interface PlanConfigEditable {
//   repos: Editable<RepoConfig>[]
//   editors: Editable<EditorConfig>[]
//   pipelineSteps: Editable<PipelineStepConfig>[]
//   views: Editable<ViewConfig>[]
// }

export function PlanEditorPage() {
  const {planId} = useParams<{planId: string}>()
  return <div className="PlanEditorPage">
    <PlanEditor planId={planId} />
  </div>
}

// function configToEditable(config: PlanConfig):PlanConfigEditable {
//   return {
//     repos: !config.repos ? [] : Object.entries(config.repos).map(([name, config]) => ({name, config, id: nextId()})), 
//     editors: !config.editors ? [] : Object.entries(config.editors).map(([name, config]) => ({name, config, id: nextId()})), 
//     pipelineSteps: !config.pipelineSteps ? [] : Object.entries(config.pipelineSteps).map(([name, config]) => ({name, config, id: nextId()})), 
//     views: !config.views ? [] : Object.entries(config.views).map(([name, config]) => ({name, config, id: nextId()})), 
//   }
// }
// function editableToConfig(editable: PlanConfigEditable):PlanConfig {
//   return {
//     repos: Object.fromEntries(editable.repos.map(({name, config}) => [name, config])),
//     pipelineSteps: Object.fromEntries(editable.pipelineSteps.map(({name, config}) => [name, config])),
//     views: Object.fromEntries(editable.views.map(({name, config}) => [name, config])),
//   }
// }

function loadPlan(planId: String): string {
  const loadedData = window.localStorage.getItem(`${PLAN_PREFIX}${planId}`)
  if (loadedData) {
    try {
      const plan = JSON.parse(loadedData)
      return plan
    } catch (e) {
      console.error('Error parsing plan' + e)
    }
  }

  return JSON.stringify({'$schema': 'http://jsan-editor.web.app/PlanConfig.json', repos: {}, editors: {}, pipelineSteps: {}, views: {}}, null, '  ')
}

interface PlanEditorProps {
  planId: string
}
export function PlanEditor({planId}: PlanEditorProps) {
  const [plan, setPlan] = useState<string>(() => loadPlan(planId))
  const [loadFrom, setLoadFrom] = useState('')
  // const setConfig = (config: PlanConfig) => {
  //   save(config)
  //   setConfigState(config)
  // }
  // const save = (config: PlanConfig) => {
  //   window.localStorage.setItem(`${PLAN_PREFIX}${planId}`, JSON.stringify(config))
  // }

  const doLoadFrom = async () => {
    const response = await window.fetch(`/plans/${loadFrom}`)
    console.log('response:', response);
    if (response.ok) {
      setPlan(await response.text())
    } else {
      alert('NO LOAD PLAN!')
    }
  }
 
  return <div className="PlanEditor">
    <header >
      <h1>PlanEditor: {planId}</h1>
      <label>Load from</label>
      <select value={loadFrom} onChange={e => setLoadFrom(e.target.value)}>
        <option />
        {plans.map(plan => <option key={`b:${plan}`} value={plan}>b:{plan}</option>)}
      </select>
      {loadFrom ? <button onClick={() => doLoadFrom()}>Load</button> : undefined }
    </header>
    <ResiazableQuads
        layout={'h=1:2'}
        fill={location => {
          if (location === '1:1') {
            return <PlanJsonEdit {...{plan, setPlan}} />
          } else return <></>
        }}
      />
  </div>
}

interface PlanJsonEditProps {
  plan: string
  setPlan: (str: string) => void
}
function PlanJsonEdit({plan, setPlan}: PlanJsonEditProps) {
  const [[line, col], setPos] = useState<[number, number]>([0, 0])
  return  <div className="MonacoEditorPanel" >
  <Editor
    height="100%"
    width="100%"
    theme="vs-dark"
    defaultLanguage={'json'}
    value={plan}
    onChange={value => value && setPlan(value)}
    options={{
      automaticLayout: true,
      tabSize: 2,
      insertSpaces: true,
    }}
    
    onMount={(editor, monaco) => {
      editor.onDidChangeCursorPosition(p => setPos([p.position.lineNumber, p.position.column])) 
      monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
        validate: true, 
        allowComments: false, 
        schemas: [{
          uri: 'http://jsan-editor.web.app/PlanConfig.json',
          schema: schema
        }, {
          uri: 'http://localhost:3000/PlanConfig.json',
          schema: schema
        }], 
        enableSchemaRequest: true
      })
    }}
  />
  <div className="status">line: {line} col: {col}</div>
</div>
}