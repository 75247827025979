import React, { useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import { ResiazableQuads } from './comp/ResizeableQuads';
import './Main.scss';
import { MenuBar } from './MenuBar';

import {loadProcessor, Processor} from './processor'
import { Quadrant } from './Quadrant';
import { useFirebaseAuth } from './react-use-firebase-auth';

interface MainProps {
  noDoc?: boolean
}
function Main({noDoc}: MainProps) {
  let { planId, docId } = useParams<{planId: string, docId?: string}>()
  const [processor, setProcessor] = useState<Processor>()
  const { user } = useFirebaseAuth();
  const dispatch = (action: string) => {
    const loggedInUser = typeof(user) === 'object' ? user : null
    console.log('loggedInUser, processor:', loggedInUser, processor)
    switch(action) {
      case "save": processor?.saveToFirebase(loggedInUser)
    }
  }

  useEffect(() => {
    let loadedProcessor: Processor
    loadProcessor(planId, docId).then(p => {
      loadedProcessor = p
      setProcessor(p)
    })

    return () => {
      if (loadedProcessor) loadedProcessor.dispose()
    }
  }, [planId, docId])
  
  if (!processor) return <div>Loading...</div>

  return (
    <div className="Main">
      <MenuBar dispatch={dispatch} processor={processor}/>
      <ResiazableQuads
        layout={processor.config.layout}
        fill={location => <Quadrant processor={processor} location={location} />}
      />
    </div>
  );
}

export default Main;