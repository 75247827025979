export const schema = `{
    "$schema": "http://json-schema.org/draft-07/schema#",
    "definitions": {
        "EditorConfig": {
            "properties": {
                "def": {
                    "type": "string"
                },
                "lang": {
                    "type": "string"
                },
                "location": {
                    "$ref": "#/definitions/QuadLocation"
                },
                "type": {
                    "enum": [
                        "monaco",
                        "textarea"
                    ],
                    "type": "string"
                }
            },
            "required": [
                "def",
                "location",
                "type"
            ],
            "type": "object"
        },
        "OutputConfig": {
            "properties": {
                "lang": {
                    "type": "string"
                },
                "location": {
                    "$ref": "#/definitions/QuadLocation"
                },
                "type": {
                    "enum": [
                        "highlight",
                        "pre",
                        "textarea"
                    ],
                    "type": "string"
                }
            },
            "required": [
                "location",
                "type"
            ],
            "type": "object"
        },
        "PipelineStepConfig": {
            "properties": {
                "other": {
                    "additionalProperties": {
                        "type": "string"
                    },
                    "type": "object"
                },
                "process": {
                    "$ref": "#/definitions/ProcessType"
                },
                "source": {
                    "type": "string"
                }
            },
            "required": [
                "process",
                "source"
            ],
            "type": "object"
        },
        "ProcessType": {
            "enum": [
                "arrToLines",
                "format",
                "format-jsonlint",
                "format-pretty",
                "jsToJson",
                "makeHtml",
                "repl",
                "run-data",
                "run-json"
            ],
            "type": "string"
        },
        "QuadLayout": {
            "enum": [
                "1x2",
                "2x1",
                "2x2",
                "h=0",
                "h=0:0",
                "h=0:0:0",
                "h=0:0:1",
                "h=0:0:2",
                "h=0:0:3",
                "h=0:0:4",
                "h=0:0:5",
                "h=0:0:6",
                "h=0:0:7",
                "h=0:0:8",
                "h=0:0:9",
                "h=0:1",
                "h=0:1:0",
                "h=0:1:1",
                "h=0:1:2",
                "h=0:1:3",
                "h=0:1:4",
                "h=0:1:5",
                "h=0:1:6",
                "h=0:1:7",
                "h=0:1:8",
                "h=0:1:9",
                "h=0:2",
                "h=0:2:0",
                "h=0:2:1",
                "h=0:2:2",
                "h=0:2:3",
                "h=0:2:4",
                "h=0:2:5",
                "h=0:2:6",
                "h=0:2:7",
                "h=0:2:8",
                "h=0:2:9",
                "h=0:3",
                "h=0:3:0",
                "h=0:3:1",
                "h=0:3:2",
                "h=0:3:3",
                "h=0:3:4",
                "h=0:3:5",
                "h=0:3:6",
                "h=0:3:7",
                "h=0:3:8",
                "h=0:3:9",
                "h=0:4",
                "h=0:4:0",
                "h=0:4:1",
                "h=0:4:2",
                "h=0:4:3",
                "h=0:4:4",
                "h=0:4:5",
                "h=0:4:6",
                "h=0:4:7",
                "h=0:4:8",
                "h=0:4:9",
                "h=0:5",
                "h=0:5:0",
                "h=0:5:1",
                "h=0:5:2",
                "h=0:5:3",
                "h=0:5:4",
                "h=0:5:5",
                "h=0:5:6",
                "h=0:5:7",
                "h=0:5:8",
                "h=0:5:9",
                "h=0:6",
                "h=0:6:0",
                "h=0:6:1",
                "h=0:6:2",
                "h=0:6:3",
                "h=0:6:4",
                "h=0:6:5",
                "h=0:6:6",
                "h=0:6:7",
                "h=0:6:8",
                "h=0:6:9",
                "h=0:7",
                "h=0:7:0",
                "h=0:7:1",
                "h=0:7:2",
                "h=0:7:3",
                "h=0:7:4",
                "h=0:7:5",
                "h=0:7:6",
                "h=0:7:7",
                "h=0:7:8",
                "h=0:7:9",
                "h=0:8",
                "h=0:8:0",
                "h=0:8:1",
                "h=0:8:2",
                "h=0:8:3",
                "h=0:8:4",
                "h=0:8:5",
                "h=0:8:6",
                "h=0:8:7",
                "h=0:8:8",
                "h=0:8:9",
                "h=0:9",
                "h=0:9:0",
                "h=0:9:1",
                "h=0:9:2",
                "h=0:9:3",
                "h=0:9:4",
                "h=0:9:5",
                "h=0:9:6",
                "h=0:9:7",
                "h=0:9:8",
                "h=0:9:9",
                "h=1",
                "h=1:0",
                "h=1:0:0",
                "h=1:0:1",
                "h=1:0:2",
                "h=1:0:3",
                "h=1:0:4",
                "h=1:0:5",
                "h=1:0:6",
                "h=1:0:7",
                "h=1:0:8",
                "h=1:0:9",
                "h=1:1",
                "h=1:1:0",
                "h=1:1:1",
                "h=1:1:2",
                "h=1:1:3",
                "h=1:1:4",
                "h=1:1:5",
                "h=1:1:6",
                "h=1:1:7",
                "h=1:1:8",
                "h=1:1:9",
                "h=1:2",
                "h=1:2:0",
                "h=1:2:1",
                "h=1:2:2",
                "h=1:2:3",
                "h=1:2:4",
                "h=1:2:5",
                "h=1:2:6",
                "h=1:2:7",
                "h=1:2:8",
                "h=1:2:9",
                "h=1:3",
                "h=1:3:0",
                "h=1:3:1",
                "h=1:3:2",
                "h=1:3:3",
                "h=1:3:4",
                "h=1:3:5",
                "h=1:3:6",
                "h=1:3:7",
                "h=1:3:8",
                "h=1:3:9",
                "h=1:4",
                "h=1:4:0",
                "h=1:4:1",
                "h=1:4:2",
                "h=1:4:3",
                "h=1:4:4",
                "h=1:4:5",
                "h=1:4:6",
                "h=1:4:7",
                "h=1:4:8",
                "h=1:4:9",
                "h=1:5",
                "h=1:5:0",
                "h=1:5:1",
                "h=1:5:2",
                "h=1:5:3",
                "h=1:5:4",
                "h=1:5:5",
                "h=1:5:6",
                "h=1:5:7",
                "h=1:5:8",
                "h=1:5:9",
                "h=1:6",
                "h=1:6:0",
                "h=1:6:1",
                "h=1:6:2",
                "h=1:6:3",
                "h=1:6:4",
                "h=1:6:5",
                "h=1:6:6",
                "h=1:6:7",
                "h=1:6:8",
                "h=1:6:9",
                "h=1:7",
                "h=1:7:0",
                "h=1:7:1",
                "h=1:7:2",
                "h=1:7:3",
                "h=1:7:4",
                "h=1:7:5",
                "h=1:7:6",
                "h=1:7:7",
                "h=1:7:8",
                "h=1:7:9",
                "h=1:8",
                "h=1:8:0",
                "h=1:8:1",
                "h=1:8:2",
                "h=1:8:3",
                "h=1:8:4",
                "h=1:8:5",
                "h=1:8:6",
                "h=1:8:7",
                "h=1:8:8",
                "h=1:8:9",
                "h=1:9",
                "h=1:9:0",
                "h=1:9:1",
                "h=1:9:2",
                "h=1:9:3",
                "h=1:9:4",
                "h=1:9:5",
                "h=1:9:6",
                "h=1:9:7",
                "h=1:9:8",
                "h=1:9:9",
                "h=2",
                "h=2:0",
                "h=2:0:0",
                "h=2:0:1",
                "h=2:0:2",
                "h=2:0:3",
                "h=2:0:4",
                "h=2:0:5",
                "h=2:0:6",
                "h=2:0:7",
                "h=2:0:8",
                "h=2:0:9",
                "h=2:1",
                "h=2:1:0",
                "h=2:1:1",
                "h=2:1:2",
                "h=2:1:3",
                "h=2:1:4",
                "h=2:1:5",
                "h=2:1:6",
                "h=2:1:7",
                "h=2:1:8",
                "h=2:1:9",
                "h=2:2",
                "h=2:2:0",
                "h=2:2:1",
                "h=2:2:2",
                "h=2:2:3",
                "h=2:2:4",
                "h=2:2:5",
                "h=2:2:6",
                "h=2:2:7",
                "h=2:2:8",
                "h=2:2:9",
                "h=2:3",
                "h=2:3:0",
                "h=2:3:1",
                "h=2:3:2",
                "h=2:3:3",
                "h=2:3:4",
                "h=2:3:5",
                "h=2:3:6",
                "h=2:3:7",
                "h=2:3:8",
                "h=2:3:9",
                "h=2:4",
                "h=2:4:0",
                "h=2:4:1",
                "h=2:4:2",
                "h=2:4:3",
                "h=2:4:4",
                "h=2:4:5",
                "h=2:4:6",
                "h=2:4:7",
                "h=2:4:8",
                "h=2:4:9",
                "h=2:5",
                "h=2:5:0",
                "h=2:5:1",
                "h=2:5:2",
                "h=2:5:3",
                "h=2:5:4",
                "h=2:5:5",
                "h=2:5:6",
                "h=2:5:7",
                "h=2:5:8",
                "h=2:5:9",
                "h=2:6",
                "h=2:6:0",
                "h=2:6:1",
                "h=2:6:2",
                "h=2:6:3",
                "h=2:6:4",
                "h=2:6:5",
                "h=2:6:6",
                "h=2:6:7",
                "h=2:6:8",
                "h=2:6:9",
                "h=2:7",
                "h=2:7:0",
                "h=2:7:1",
                "h=2:7:2",
                "h=2:7:3",
                "h=2:7:4",
                "h=2:7:5",
                "h=2:7:6",
                "h=2:7:7",
                "h=2:7:8",
                "h=2:7:9",
                "h=2:8",
                "h=2:8:0",
                "h=2:8:1",
                "h=2:8:2",
                "h=2:8:3",
                "h=2:8:4",
                "h=2:8:5",
                "h=2:8:6",
                "h=2:8:7",
                "h=2:8:8",
                "h=2:8:9",
                "h=2:9",
                "h=2:9:0",
                "h=2:9:1",
                "h=2:9:2",
                "h=2:9:3",
                "h=2:9:4",
                "h=2:9:5",
                "h=2:9:6",
                "h=2:9:7",
                "h=2:9:8",
                "h=2:9:9",
                "h=3",
                "h=3:0",
                "h=3:0:0",
                "h=3:0:1",
                "h=3:0:2",
                "h=3:0:3",
                "h=3:0:4",
                "h=3:0:5",
                "h=3:0:6",
                "h=3:0:7",
                "h=3:0:8",
                "h=3:0:9",
                "h=3:1",
                "h=3:1:0",
                "h=3:1:1",
                "h=3:1:2",
                "h=3:1:3",
                "h=3:1:4",
                "h=3:1:5",
                "h=3:1:6",
                "h=3:1:7",
                "h=3:1:8",
                "h=3:1:9",
                "h=3:2",
                "h=3:2:0",
                "h=3:2:1",
                "h=3:2:2",
                "h=3:2:3",
                "h=3:2:4",
                "h=3:2:5",
                "h=3:2:6",
                "h=3:2:7",
                "h=3:2:8",
                "h=3:2:9",
                "h=3:3",
                "h=3:3:0",
                "h=3:3:1",
                "h=3:3:2",
                "h=3:3:3",
                "h=3:3:4",
                "h=3:3:5",
                "h=3:3:6",
                "h=3:3:7",
                "h=3:3:8",
                "h=3:3:9",
                "h=3:4",
                "h=3:4:0",
                "h=3:4:1",
                "h=3:4:2",
                "h=3:4:3",
                "h=3:4:4",
                "h=3:4:5",
                "h=3:4:6",
                "h=3:4:7",
                "h=3:4:8",
                "h=3:4:9",
                "h=3:5",
                "h=3:5:0",
                "h=3:5:1",
                "h=3:5:2",
                "h=3:5:3",
                "h=3:5:4",
                "h=3:5:5",
                "h=3:5:6",
                "h=3:5:7",
                "h=3:5:8",
                "h=3:5:9",
                "h=3:6",
                "h=3:6:0",
                "h=3:6:1",
                "h=3:6:2",
                "h=3:6:3",
                "h=3:6:4",
                "h=3:6:5",
                "h=3:6:6",
                "h=3:6:7",
                "h=3:6:8",
                "h=3:6:9",
                "h=3:7",
                "h=3:7:0",
                "h=3:7:1",
                "h=3:7:2",
                "h=3:7:3",
                "h=3:7:4",
                "h=3:7:5",
                "h=3:7:6",
                "h=3:7:7",
                "h=3:7:8",
                "h=3:7:9",
                "h=3:8",
                "h=3:8:0",
                "h=3:8:1",
                "h=3:8:2",
                "h=3:8:3",
                "h=3:8:4",
                "h=3:8:5",
                "h=3:8:6",
                "h=3:8:7",
                "h=3:8:8",
                "h=3:8:9",
                "h=3:9",
                "h=3:9:0",
                "h=3:9:1",
                "h=3:9:2",
                "h=3:9:3",
                "h=3:9:4",
                "h=3:9:5",
                "h=3:9:6",
                "h=3:9:7",
                "h=3:9:8",
                "h=3:9:9",
                "h=4",
                "h=4:0",
                "h=4:0:0",
                "h=4:0:1",
                "h=4:0:2",
                "h=4:0:3",
                "h=4:0:4",
                "h=4:0:5",
                "h=4:0:6",
                "h=4:0:7",
                "h=4:0:8",
                "h=4:0:9",
                "h=4:1",
                "h=4:1:0",
                "h=4:1:1",
                "h=4:1:2",
                "h=4:1:3",
                "h=4:1:4",
                "h=4:1:5",
                "h=4:1:6",
                "h=4:1:7",
                "h=4:1:8",
                "h=4:1:9",
                "h=4:2",
                "h=4:2:0",
                "h=4:2:1",
                "h=4:2:2",
                "h=4:2:3",
                "h=4:2:4",
                "h=4:2:5",
                "h=4:2:6",
                "h=4:2:7",
                "h=4:2:8",
                "h=4:2:9",
                "h=4:3",
                "h=4:3:0",
                "h=4:3:1",
                "h=4:3:2",
                "h=4:3:3",
                "h=4:3:4",
                "h=4:3:5",
                "h=4:3:6",
                "h=4:3:7",
                "h=4:3:8",
                "h=4:3:9",
                "h=4:4",
                "h=4:4:0",
                "h=4:4:1",
                "h=4:4:2",
                "h=4:4:3",
                "h=4:4:4",
                "h=4:4:5",
                "h=4:4:6",
                "h=4:4:7",
                "h=4:4:8",
                "h=4:4:9",
                "h=4:5",
                "h=4:5:0",
                "h=4:5:1",
                "h=4:5:2",
                "h=4:5:3",
                "h=4:5:4",
                "h=4:5:5",
                "h=4:5:6",
                "h=4:5:7",
                "h=4:5:8",
                "h=4:5:9",
                "h=4:6",
                "h=4:6:0",
                "h=4:6:1",
                "h=4:6:2",
                "h=4:6:3",
                "h=4:6:4",
                "h=4:6:5",
                "h=4:6:6",
                "h=4:6:7",
                "h=4:6:8",
                "h=4:6:9",
                "h=4:7",
                "h=4:7:0",
                "h=4:7:1",
                "h=4:7:2",
                "h=4:7:3",
                "h=4:7:4",
                "h=4:7:5",
                "h=4:7:6",
                "h=4:7:7",
                "h=4:7:8",
                "h=4:7:9",
                "h=4:8",
                "h=4:8:0",
                "h=4:8:1",
                "h=4:8:2",
                "h=4:8:3",
                "h=4:8:4",
                "h=4:8:5",
                "h=4:8:6",
                "h=4:8:7",
                "h=4:8:8",
                "h=4:8:9",
                "h=4:9",
                "h=4:9:0",
                "h=4:9:1",
                "h=4:9:2",
                "h=4:9:3",
                "h=4:9:4",
                "h=4:9:5",
                "h=4:9:6",
                "h=4:9:7",
                "h=4:9:8",
                "h=4:9:9",
                "h=5",
                "h=5:0",
                "h=5:0:0",
                "h=5:0:1",
                "h=5:0:2",
                "h=5:0:3",
                "h=5:0:4",
                "h=5:0:5",
                "h=5:0:6",
                "h=5:0:7",
                "h=5:0:8",
                "h=5:0:9",
                "h=5:1",
                "h=5:1:0",
                "h=5:1:1",
                "h=5:1:2",
                "h=5:1:3",
                "h=5:1:4",
                "h=5:1:5",
                "h=5:1:6",
                "h=5:1:7",
                "h=5:1:8",
                "h=5:1:9",
                "h=5:2",
                "h=5:2:0",
                "h=5:2:1",
                "h=5:2:2",
                "h=5:2:3",
                "h=5:2:4",
                "h=5:2:5",
                "h=5:2:6",
                "h=5:2:7",
                "h=5:2:8",
                "h=5:2:9",
                "h=5:3",
                "h=5:3:0",
                "h=5:3:1",
                "h=5:3:2",
                "h=5:3:3",
                "h=5:3:4",
                "h=5:3:5",
                "h=5:3:6",
                "h=5:3:7",
                "h=5:3:8",
                "h=5:3:9",
                "h=5:4",
                "h=5:4:0",
                "h=5:4:1",
                "h=5:4:2",
                "h=5:4:3",
                "h=5:4:4",
                "h=5:4:5",
                "h=5:4:6",
                "h=5:4:7",
                "h=5:4:8",
                "h=5:4:9",
                "h=5:5",
                "h=5:5:0",
                "h=5:5:1",
                "h=5:5:2",
                "h=5:5:3",
                "h=5:5:4",
                "h=5:5:5",
                "h=5:5:6",
                "h=5:5:7",
                "h=5:5:8",
                "h=5:5:9",
                "h=5:6",
                "h=5:6:0",
                "h=5:6:1",
                "h=5:6:2",
                "h=5:6:3",
                "h=5:6:4",
                "h=5:6:5",
                "h=5:6:6",
                "h=5:6:7",
                "h=5:6:8",
                "h=5:6:9",
                "h=5:7",
                "h=5:7:0",
                "h=5:7:1",
                "h=5:7:2",
                "h=5:7:3",
                "h=5:7:4",
                "h=5:7:5",
                "h=5:7:6",
                "h=5:7:7",
                "h=5:7:8",
                "h=5:7:9",
                "h=5:8",
                "h=5:8:0",
                "h=5:8:1",
                "h=5:8:2",
                "h=5:8:3",
                "h=5:8:4",
                "h=5:8:5",
                "h=5:8:6",
                "h=5:8:7",
                "h=5:8:8",
                "h=5:8:9",
                "h=5:9",
                "h=5:9:0",
                "h=5:9:1",
                "h=5:9:2",
                "h=5:9:3",
                "h=5:9:4",
                "h=5:9:5",
                "h=5:9:6",
                "h=5:9:7",
                "h=5:9:8",
                "h=5:9:9",
                "h=6",
                "h=6:0",
                "h=6:0:0",
                "h=6:0:1",
                "h=6:0:2",
                "h=6:0:3",
                "h=6:0:4",
                "h=6:0:5",
                "h=6:0:6",
                "h=6:0:7",
                "h=6:0:8",
                "h=6:0:9",
                "h=6:1",
                "h=6:1:0",
                "h=6:1:1",
                "h=6:1:2",
                "h=6:1:3",
                "h=6:1:4",
                "h=6:1:5",
                "h=6:1:6",
                "h=6:1:7",
                "h=6:1:8",
                "h=6:1:9",
                "h=6:2",
                "h=6:2:0",
                "h=6:2:1",
                "h=6:2:2",
                "h=6:2:3",
                "h=6:2:4",
                "h=6:2:5",
                "h=6:2:6",
                "h=6:2:7",
                "h=6:2:8",
                "h=6:2:9",
                "h=6:3",
                "h=6:3:0",
                "h=6:3:1",
                "h=6:3:2",
                "h=6:3:3",
                "h=6:3:4",
                "h=6:3:5",
                "h=6:3:6",
                "h=6:3:7",
                "h=6:3:8",
                "h=6:3:9",
                "h=6:4",
                "h=6:4:0",
                "h=6:4:1",
                "h=6:4:2",
                "h=6:4:3",
                "h=6:4:4",
                "h=6:4:5",
                "h=6:4:6",
                "h=6:4:7",
                "h=6:4:8",
                "h=6:4:9",
                "h=6:5",
                "h=6:5:0",
                "h=6:5:1",
                "h=6:5:2",
                "h=6:5:3",
                "h=6:5:4",
                "h=6:5:5",
                "h=6:5:6",
                "h=6:5:7",
                "h=6:5:8",
                "h=6:5:9",
                "h=6:6",
                "h=6:6:0",
                "h=6:6:1",
                "h=6:6:2",
                "h=6:6:3",
                "h=6:6:4",
                "h=6:6:5",
                "h=6:6:6",
                "h=6:6:7",
                "h=6:6:8",
                "h=6:6:9",
                "h=6:7",
                "h=6:7:0",
                "h=6:7:1",
                "h=6:7:2",
                "h=6:7:3",
                "h=6:7:4",
                "h=6:7:5",
                "h=6:7:6",
                "h=6:7:7",
                "h=6:7:8",
                "h=6:7:9",
                "h=6:8",
                "h=6:8:0",
                "h=6:8:1",
                "h=6:8:2",
                "h=6:8:3",
                "h=6:8:4",
                "h=6:8:5",
                "h=6:8:6",
                "h=6:8:7",
                "h=6:8:8",
                "h=6:8:9",
                "h=6:9",
                "h=6:9:0",
                "h=6:9:1",
                "h=6:9:2",
                "h=6:9:3",
                "h=6:9:4",
                "h=6:9:5",
                "h=6:9:6",
                "h=6:9:7",
                "h=6:9:8",
                "h=6:9:9",
                "h=7",
                "h=7:0",
                "h=7:0:0",
                "h=7:0:1",
                "h=7:0:2",
                "h=7:0:3",
                "h=7:0:4",
                "h=7:0:5",
                "h=7:0:6",
                "h=7:0:7",
                "h=7:0:8",
                "h=7:0:9",
                "h=7:1",
                "h=7:1:0",
                "h=7:1:1",
                "h=7:1:2",
                "h=7:1:3",
                "h=7:1:4",
                "h=7:1:5",
                "h=7:1:6",
                "h=7:1:7",
                "h=7:1:8",
                "h=7:1:9",
                "h=7:2",
                "h=7:2:0",
                "h=7:2:1",
                "h=7:2:2",
                "h=7:2:3",
                "h=7:2:4",
                "h=7:2:5",
                "h=7:2:6",
                "h=7:2:7",
                "h=7:2:8",
                "h=7:2:9",
                "h=7:3",
                "h=7:3:0",
                "h=7:3:1",
                "h=7:3:2",
                "h=7:3:3",
                "h=7:3:4",
                "h=7:3:5",
                "h=7:3:6",
                "h=7:3:7",
                "h=7:3:8",
                "h=7:3:9",
                "h=7:4",
                "h=7:4:0",
                "h=7:4:1",
                "h=7:4:2",
                "h=7:4:3",
                "h=7:4:4",
                "h=7:4:5",
                "h=7:4:6",
                "h=7:4:7",
                "h=7:4:8",
                "h=7:4:9",
                "h=7:5",
                "h=7:5:0",
                "h=7:5:1",
                "h=7:5:2",
                "h=7:5:3",
                "h=7:5:4",
                "h=7:5:5",
                "h=7:5:6",
                "h=7:5:7",
                "h=7:5:8",
                "h=7:5:9",
                "h=7:6",
                "h=7:6:0",
                "h=7:6:1",
                "h=7:6:2",
                "h=7:6:3",
                "h=7:6:4",
                "h=7:6:5",
                "h=7:6:6",
                "h=7:6:7",
                "h=7:6:8",
                "h=7:6:9",
                "h=7:7",
                "h=7:7:0",
                "h=7:7:1",
                "h=7:7:2",
                "h=7:7:3",
                "h=7:7:4",
                "h=7:7:5",
                "h=7:7:6",
                "h=7:7:7",
                "h=7:7:8",
                "h=7:7:9",
                "h=7:8",
                "h=7:8:0",
                "h=7:8:1",
                "h=7:8:2",
                "h=7:8:3",
                "h=7:8:4",
                "h=7:8:5",
                "h=7:8:6",
                "h=7:8:7",
                "h=7:8:8",
                "h=7:8:9",
                "h=7:9",
                "h=7:9:0",
                "h=7:9:1",
                "h=7:9:2",
                "h=7:9:3",
                "h=7:9:4",
                "h=7:9:5",
                "h=7:9:6",
                "h=7:9:7",
                "h=7:9:8",
                "h=7:9:9",
                "h=8",
                "h=8:0",
                "h=8:0:0",
                "h=8:0:1",
                "h=8:0:2",
                "h=8:0:3",
                "h=8:0:4",
                "h=8:0:5",
                "h=8:0:6",
                "h=8:0:7",
                "h=8:0:8",
                "h=8:0:9",
                "h=8:1",
                "h=8:1:0",
                "h=8:1:1",
                "h=8:1:2",
                "h=8:1:3",
                "h=8:1:4",
                "h=8:1:5",
                "h=8:1:6",
                "h=8:1:7",
                "h=8:1:8",
                "h=8:1:9",
                "h=8:2",
                "h=8:2:0",
                "h=8:2:1",
                "h=8:2:2",
                "h=8:2:3",
                "h=8:2:4",
                "h=8:2:5",
                "h=8:2:6",
                "h=8:2:7",
                "h=8:2:8",
                "h=8:2:9",
                "h=8:3",
                "h=8:3:0",
                "h=8:3:1",
                "h=8:3:2",
                "h=8:3:3",
                "h=8:3:4",
                "h=8:3:5",
                "h=8:3:6",
                "h=8:3:7",
                "h=8:3:8",
                "h=8:3:9",
                "h=8:4",
                "h=8:4:0",
                "h=8:4:1",
                "h=8:4:2",
                "h=8:4:3",
                "h=8:4:4",
                "h=8:4:5",
                "h=8:4:6",
                "h=8:4:7",
                "h=8:4:8",
                "h=8:4:9",
                "h=8:5",
                "h=8:5:0",
                "h=8:5:1",
                "h=8:5:2",
                "h=8:5:3",
                "h=8:5:4",
                "h=8:5:5",
                "h=8:5:6",
                "h=8:5:7",
                "h=8:5:8",
                "h=8:5:9",
                "h=8:6",
                "h=8:6:0",
                "h=8:6:1",
                "h=8:6:2",
                "h=8:6:3",
                "h=8:6:4",
                "h=8:6:5",
                "h=8:6:6",
                "h=8:6:7",
                "h=8:6:8",
                "h=8:6:9",
                "h=8:7",
                "h=8:7:0",
                "h=8:7:1",
                "h=8:7:2",
                "h=8:7:3",
                "h=8:7:4",
                "h=8:7:5",
                "h=8:7:6",
                "h=8:7:7",
                "h=8:7:8",
                "h=8:7:9",
                "h=8:8",
                "h=8:8:0",
                "h=8:8:1",
                "h=8:8:2",
                "h=8:8:3",
                "h=8:8:4",
                "h=8:8:5",
                "h=8:8:6",
                "h=8:8:7",
                "h=8:8:8",
                "h=8:8:9",
                "h=8:9",
                "h=8:9:0",
                "h=8:9:1",
                "h=8:9:2",
                "h=8:9:3",
                "h=8:9:4",
                "h=8:9:5",
                "h=8:9:6",
                "h=8:9:7",
                "h=8:9:8",
                "h=8:9:9",
                "h=9",
                "h=9:0",
                "h=9:0:0",
                "h=9:0:1",
                "h=9:0:2",
                "h=9:0:3",
                "h=9:0:4",
                "h=9:0:5",
                "h=9:0:6",
                "h=9:0:7",
                "h=9:0:8",
                "h=9:0:9",
                "h=9:1",
                "h=9:1:0",
                "h=9:1:1",
                "h=9:1:2",
                "h=9:1:3",
                "h=9:1:4",
                "h=9:1:5",
                "h=9:1:6",
                "h=9:1:7",
                "h=9:1:8",
                "h=9:1:9",
                "h=9:2",
                "h=9:2:0",
                "h=9:2:1",
                "h=9:2:2",
                "h=9:2:3",
                "h=9:2:4",
                "h=9:2:5",
                "h=9:2:6",
                "h=9:2:7",
                "h=9:2:8",
                "h=9:2:9",
                "h=9:3",
                "h=9:3:0",
                "h=9:3:1",
                "h=9:3:2",
                "h=9:3:3",
                "h=9:3:4",
                "h=9:3:5",
                "h=9:3:6",
                "h=9:3:7",
                "h=9:3:8",
                "h=9:3:9",
                "h=9:4",
                "h=9:4:0",
                "h=9:4:1",
                "h=9:4:2",
                "h=9:4:3",
                "h=9:4:4",
                "h=9:4:5",
                "h=9:4:6",
                "h=9:4:7",
                "h=9:4:8",
                "h=9:4:9",
                "h=9:5",
                "h=9:5:0",
                "h=9:5:1",
                "h=9:5:2",
                "h=9:5:3",
                "h=9:5:4",
                "h=9:5:5",
                "h=9:5:6",
                "h=9:5:7",
                "h=9:5:8",
                "h=9:5:9",
                "h=9:6",
                "h=9:6:0",
                "h=9:6:1",
                "h=9:6:2",
                "h=9:6:3",
                "h=9:6:4",
                "h=9:6:5",
                "h=9:6:6",
                "h=9:6:7",
                "h=9:6:8",
                "h=9:6:9",
                "h=9:7",
                "h=9:7:0",
                "h=9:7:1",
                "h=9:7:2",
                "h=9:7:3",
                "h=9:7:4",
                "h=9:7:5",
                "h=9:7:6",
                "h=9:7:7",
                "h=9:7:8",
                "h=9:7:9",
                "h=9:8",
                "h=9:8:0",
                "h=9:8:1",
                "h=9:8:2",
                "h=9:8:3",
                "h=9:8:4",
                "h=9:8:5",
                "h=9:8:6",
                "h=9:8:7",
                "h=9:8:8",
                "h=9:8:9",
                "h=9:9",
                "h=9:9:0",
                "h=9:9:1",
                "h=9:9:2",
                "h=9:9:3",
                "h=9:9:4",
                "h=9:9:5",
                "h=9:9:6",
                "h=9:9:7",
                "h=9:9:8",
                "h=9:9:9",
                "v=0",
                "v=0:0",
                "v=0:0:0",
                "v=0:0:1",
                "v=0:0:2",
                "v=0:0:3",
                "v=0:0:4",
                "v=0:0:5",
                "v=0:0:6",
                "v=0:0:7",
                "v=0:0:8",
                "v=0:0:9",
                "v=0:1",
                "v=0:1:0",
                "v=0:1:1",
                "v=0:1:2",
                "v=0:1:3",
                "v=0:1:4",
                "v=0:1:5",
                "v=0:1:6",
                "v=0:1:7",
                "v=0:1:8",
                "v=0:1:9",
                "v=0:2",
                "v=0:2:0",
                "v=0:2:1",
                "v=0:2:2",
                "v=0:2:3",
                "v=0:2:4",
                "v=0:2:5",
                "v=0:2:6",
                "v=0:2:7",
                "v=0:2:8",
                "v=0:2:9",
                "v=0:3",
                "v=0:3:0",
                "v=0:3:1",
                "v=0:3:2",
                "v=0:3:3",
                "v=0:3:4",
                "v=0:3:5",
                "v=0:3:6",
                "v=0:3:7",
                "v=0:3:8",
                "v=0:3:9",
                "v=0:4",
                "v=0:4:0",
                "v=0:4:1",
                "v=0:4:2",
                "v=0:4:3",
                "v=0:4:4",
                "v=0:4:5",
                "v=0:4:6",
                "v=0:4:7",
                "v=0:4:8",
                "v=0:4:9",
                "v=0:5",
                "v=0:5:0",
                "v=0:5:1",
                "v=0:5:2",
                "v=0:5:3",
                "v=0:5:4",
                "v=0:5:5",
                "v=0:5:6",
                "v=0:5:7",
                "v=0:5:8",
                "v=0:5:9",
                "v=0:6",
                "v=0:6:0",
                "v=0:6:1",
                "v=0:6:2",
                "v=0:6:3",
                "v=0:6:4",
                "v=0:6:5",
                "v=0:6:6",
                "v=0:6:7",
                "v=0:6:8",
                "v=0:6:9",
                "v=0:7",
                "v=0:7:0",
                "v=0:7:1",
                "v=0:7:2",
                "v=0:7:3",
                "v=0:7:4",
                "v=0:7:5",
                "v=0:7:6",
                "v=0:7:7",
                "v=0:7:8",
                "v=0:7:9",
                "v=0:8",
                "v=0:8:0",
                "v=0:8:1",
                "v=0:8:2",
                "v=0:8:3",
                "v=0:8:4",
                "v=0:8:5",
                "v=0:8:6",
                "v=0:8:7",
                "v=0:8:8",
                "v=0:8:9",
                "v=0:9",
                "v=0:9:0",
                "v=0:9:1",
                "v=0:9:2",
                "v=0:9:3",
                "v=0:9:4",
                "v=0:9:5",
                "v=0:9:6",
                "v=0:9:7",
                "v=0:9:8",
                "v=0:9:9",
                "v=1",
                "v=1:0",
                "v=1:0:0",
                "v=1:0:1",
                "v=1:0:2",
                "v=1:0:3",
                "v=1:0:4",
                "v=1:0:5",
                "v=1:0:6",
                "v=1:0:7",
                "v=1:0:8",
                "v=1:0:9",
                "v=1:1",
                "v=1:1:0",
                "v=1:1:1",
                "v=1:1:2",
                "v=1:1:3",
                "v=1:1:4",
                "v=1:1:5",
                "v=1:1:6",
                "v=1:1:7",
                "v=1:1:8",
                "v=1:1:9",
                "v=1:2",
                "v=1:2:0",
                "v=1:2:1",
                "v=1:2:2",
                "v=1:2:3",
                "v=1:2:4",
                "v=1:2:5",
                "v=1:2:6",
                "v=1:2:7",
                "v=1:2:8",
                "v=1:2:9",
                "v=1:3",
                "v=1:3:0",
                "v=1:3:1",
                "v=1:3:2",
                "v=1:3:3",
                "v=1:3:4",
                "v=1:3:5",
                "v=1:3:6",
                "v=1:3:7",
                "v=1:3:8",
                "v=1:3:9",
                "v=1:4",
                "v=1:4:0",
                "v=1:4:1",
                "v=1:4:2",
                "v=1:4:3",
                "v=1:4:4",
                "v=1:4:5",
                "v=1:4:6",
                "v=1:4:7",
                "v=1:4:8",
                "v=1:4:9",
                "v=1:5",
                "v=1:5:0",
                "v=1:5:1",
                "v=1:5:2",
                "v=1:5:3",
                "v=1:5:4",
                "v=1:5:5",
                "v=1:5:6",
                "v=1:5:7",
                "v=1:5:8",
                "v=1:5:9",
                "v=1:6",
                "v=1:6:0",
                "v=1:6:1",
                "v=1:6:2",
                "v=1:6:3",
                "v=1:6:4",
                "v=1:6:5",
                "v=1:6:6",
                "v=1:6:7",
                "v=1:6:8",
                "v=1:6:9",
                "v=1:7",
                "v=1:7:0",
                "v=1:7:1",
                "v=1:7:2",
                "v=1:7:3",
                "v=1:7:4",
                "v=1:7:5",
                "v=1:7:6",
                "v=1:7:7",
                "v=1:7:8",
                "v=1:7:9",
                "v=1:8",
                "v=1:8:0",
                "v=1:8:1",
                "v=1:8:2",
                "v=1:8:3",
                "v=1:8:4",
                "v=1:8:5",
                "v=1:8:6",
                "v=1:8:7",
                "v=1:8:8",
                "v=1:8:9",
                "v=1:9",
                "v=1:9:0",
                "v=1:9:1",
                "v=1:9:2",
                "v=1:9:3",
                "v=1:9:4",
                "v=1:9:5",
                "v=1:9:6",
                "v=1:9:7",
                "v=1:9:8",
                "v=1:9:9",
                "v=2",
                "v=2:0",
                "v=2:0:0",
                "v=2:0:1",
                "v=2:0:2",
                "v=2:0:3",
                "v=2:0:4",
                "v=2:0:5",
                "v=2:0:6",
                "v=2:0:7",
                "v=2:0:8",
                "v=2:0:9",
                "v=2:1",
                "v=2:1:0",
                "v=2:1:1",
                "v=2:1:2",
                "v=2:1:3",
                "v=2:1:4",
                "v=2:1:5",
                "v=2:1:6",
                "v=2:1:7",
                "v=2:1:8",
                "v=2:1:9",
                "v=2:2",
                "v=2:2:0",
                "v=2:2:1",
                "v=2:2:2",
                "v=2:2:3",
                "v=2:2:4",
                "v=2:2:5",
                "v=2:2:6",
                "v=2:2:7",
                "v=2:2:8",
                "v=2:2:9",
                "v=2:3",
                "v=2:3:0",
                "v=2:3:1",
                "v=2:3:2",
                "v=2:3:3",
                "v=2:3:4",
                "v=2:3:5",
                "v=2:3:6",
                "v=2:3:7",
                "v=2:3:8",
                "v=2:3:9",
                "v=2:4",
                "v=2:4:0",
                "v=2:4:1",
                "v=2:4:2",
                "v=2:4:3",
                "v=2:4:4",
                "v=2:4:5",
                "v=2:4:6",
                "v=2:4:7",
                "v=2:4:8",
                "v=2:4:9",
                "v=2:5",
                "v=2:5:0",
                "v=2:5:1",
                "v=2:5:2",
                "v=2:5:3",
                "v=2:5:4",
                "v=2:5:5",
                "v=2:5:6",
                "v=2:5:7",
                "v=2:5:8",
                "v=2:5:9",
                "v=2:6",
                "v=2:6:0",
                "v=2:6:1",
                "v=2:6:2",
                "v=2:6:3",
                "v=2:6:4",
                "v=2:6:5",
                "v=2:6:6",
                "v=2:6:7",
                "v=2:6:8",
                "v=2:6:9",
                "v=2:7",
                "v=2:7:0",
                "v=2:7:1",
                "v=2:7:2",
                "v=2:7:3",
                "v=2:7:4",
                "v=2:7:5",
                "v=2:7:6",
                "v=2:7:7",
                "v=2:7:8",
                "v=2:7:9",
                "v=2:8",
                "v=2:8:0",
                "v=2:8:1",
                "v=2:8:2",
                "v=2:8:3",
                "v=2:8:4",
                "v=2:8:5",
                "v=2:8:6",
                "v=2:8:7",
                "v=2:8:8",
                "v=2:8:9",
                "v=2:9",
                "v=2:9:0",
                "v=2:9:1",
                "v=2:9:2",
                "v=2:9:3",
                "v=2:9:4",
                "v=2:9:5",
                "v=2:9:6",
                "v=2:9:7",
                "v=2:9:8",
                "v=2:9:9",
                "v=3",
                "v=3:0",
                "v=3:0:0",
                "v=3:0:1",
                "v=3:0:2",
                "v=3:0:3",
                "v=3:0:4",
                "v=3:0:5",
                "v=3:0:6",
                "v=3:0:7",
                "v=3:0:8",
                "v=3:0:9",
                "v=3:1",
                "v=3:1:0",
                "v=3:1:1",
                "v=3:1:2",
                "v=3:1:3",
                "v=3:1:4",
                "v=3:1:5",
                "v=3:1:6",
                "v=3:1:7",
                "v=3:1:8",
                "v=3:1:9",
                "v=3:2",
                "v=3:2:0",
                "v=3:2:1",
                "v=3:2:2",
                "v=3:2:3",
                "v=3:2:4",
                "v=3:2:5",
                "v=3:2:6",
                "v=3:2:7",
                "v=3:2:8",
                "v=3:2:9",
                "v=3:3",
                "v=3:3:0",
                "v=3:3:1",
                "v=3:3:2",
                "v=3:3:3",
                "v=3:3:4",
                "v=3:3:5",
                "v=3:3:6",
                "v=3:3:7",
                "v=3:3:8",
                "v=3:3:9",
                "v=3:4",
                "v=3:4:0",
                "v=3:4:1",
                "v=3:4:2",
                "v=3:4:3",
                "v=3:4:4",
                "v=3:4:5",
                "v=3:4:6",
                "v=3:4:7",
                "v=3:4:8",
                "v=3:4:9",
                "v=3:5",
                "v=3:5:0",
                "v=3:5:1",
                "v=3:5:2",
                "v=3:5:3",
                "v=3:5:4",
                "v=3:5:5",
                "v=3:5:6",
                "v=3:5:7",
                "v=3:5:8",
                "v=3:5:9",
                "v=3:6",
                "v=3:6:0",
                "v=3:6:1",
                "v=3:6:2",
                "v=3:6:3",
                "v=3:6:4",
                "v=3:6:5",
                "v=3:6:6",
                "v=3:6:7",
                "v=3:6:8",
                "v=3:6:9",
                "v=3:7",
                "v=3:7:0",
                "v=3:7:1",
                "v=3:7:2",
                "v=3:7:3",
                "v=3:7:4",
                "v=3:7:5",
                "v=3:7:6",
                "v=3:7:7",
                "v=3:7:8",
                "v=3:7:9",
                "v=3:8",
                "v=3:8:0",
                "v=3:8:1",
                "v=3:8:2",
                "v=3:8:3",
                "v=3:8:4",
                "v=3:8:5",
                "v=3:8:6",
                "v=3:8:7",
                "v=3:8:8",
                "v=3:8:9",
                "v=3:9",
                "v=3:9:0",
                "v=3:9:1",
                "v=3:9:2",
                "v=3:9:3",
                "v=3:9:4",
                "v=3:9:5",
                "v=3:9:6",
                "v=3:9:7",
                "v=3:9:8",
                "v=3:9:9",
                "v=4",
                "v=4:0",
                "v=4:0:0",
                "v=4:0:1",
                "v=4:0:2",
                "v=4:0:3",
                "v=4:0:4",
                "v=4:0:5",
                "v=4:0:6",
                "v=4:0:7",
                "v=4:0:8",
                "v=4:0:9",
                "v=4:1",
                "v=4:1:0",
                "v=4:1:1",
                "v=4:1:2",
                "v=4:1:3",
                "v=4:1:4",
                "v=4:1:5",
                "v=4:1:6",
                "v=4:1:7",
                "v=4:1:8",
                "v=4:1:9",
                "v=4:2",
                "v=4:2:0",
                "v=4:2:1",
                "v=4:2:2",
                "v=4:2:3",
                "v=4:2:4",
                "v=4:2:5",
                "v=4:2:6",
                "v=4:2:7",
                "v=4:2:8",
                "v=4:2:9",
                "v=4:3",
                "v=4:3:0",
                "v=4:3:1",
                "v=4:3:2",
                "v=4:3:3",
                "v=4:3:4",
                "v=4:3:5",
                "v=4:3:6",
                "v=4:3:7",
                "v=4:3:8",
                "v=4:3:9",
                "v=4:4",
                "v=4:4:0",
                "v=4:4:1",
                "v=4:4:2",
                "v=4:4:3",
                "v=4:4:4",
                "v=4:4:5",
                "v=4:4:6",
                "v=4:4:7",
                "v=4:4:8",
                "v=4:4:9",
                "v=4:5",
                "v=4:5:0",
                "v=4:5:1",
                "v=4:5:2",
                "v=4:5:3",
                "v=4:5:4",
                "v=4:5:5",
                "v=4:5:6",
                "v=4:5:7",
                "v=4:5:8",
                "v=4:5:9",
                "v=4:6",
                "v=4:6:0",
                "v=4:6:1",
                "v=4:6:2",
                "v=4:6:3",
                "v=4:6:4",
                "v=4:6:5",
                "v=4:6:6",
                "v=4:6:7",
                "v=4:6:8",
                "v=4:6:9",
                "v=4:7",
                "v=4:7:0",
                "v=4:7:1",
                "v=4:7:2",
                "v=4:7:3",
                "v=4:7:4",
                "v=4:7:5",
                "v=4:7:6",
                "v=4:7:7",
                "v=4:7:8",
                "v=4:7:9",
                "v=4:8",
                "v=4:8:0",
                "v=4:8:1",
                "v=4:8:2",
                "v=4:8:3",
                "v=4:8:4",
                "v=4:8:5",
                "v=4:8:6",
                "v=4:8:7",
                "v=4:8:8",
                "v=4:8:9",
                "v=4:9",
                "v=4:9:0",
                "v=4:9:1",
                "v=4:9:2",
                "v=4:9:3",
                "v=4:9:4",
                "v=4:9:5",
                "v=4:9:6",
                "v=4:9:7",
                "v=4:9:8",
                "v=4:9:9",
                "v=5",
                "v=5:0",
                "v=5:0:0",
                "v=5:0:1",
                "v=5:0:2",
                "v=5:0:3",
                "v=5:0:4",
                "v=5:0:5",
                "v=5:0:6",
                "v=5:0:7",
                "v=5:0:8",
                "v=5:0:9",
                "v=5:1",
                "v=5:1:0",
                "v=5:1:1",
                "v=5:1:2",
                "v=5:1:3",
                "v=5:1:4",
                "v=5:1:5",
                "v=5:1:6",
                "v=5:1:7",
                "v=5:1:8",
                "v=5:1:9",
                "v=5:2",
                "v=5:2:0",
                "v=5:2:1",
                "v=5:2:2",
                "v=5:2:3",
                "v=5:2:4",
                "v=5:2:5",
                "v=5:2:6",
                "v=5:2:7",
                "v=5:2:8",
                "v=5:2:9",
                "v=5:3",
                "v=5:3:0",
                "v=5:3:1",
                "v=5:3:2",
                "v=5:3:3",
                "v=5:3:4",
                "v=5:3:5",
                "v=5:3:6",
                "v=5:3:7",
                "v=5:3:8",
                "v=5:3:9",
                "v=5:4",
                "v=5:4:0",
                "v=5:4:1",
                "v=5:4:2",
                "v=5:4:3",
                "v=5:4:4",
                "v=5:4:5",
                "v=5:4:6",
                "v=5:4:7",
                "v=5:4:8",
                "v=5:4:9",
                "v=5:5",
                "v=5:5:0",
                "v=5:5:1",
                "v=5:5:2",
                "v=5:5:3",
                "v=5:5:4",
                "v=5:5:5",
                "v=5:5:6",
                "v=5:5:7",
                "v=5:5:8",
                "v=5:5:9",
                "v=5:6",
                "v=5:6:0",
                "v=5:6:1",
                "v=5:6:2",
                "v=5:6:3",
                "v=5:6:4",
                "v=5:6:5",
                "v=5:6:6",
                "v=5:6:7",
                "v=5:6:8",
                "v=5:6:9",
                "v=5:7",
                "v=5:7:0",
                "v=5:7:1",
                "v=5:7:2",
                "v=5:7:3",
                "v=5:7:4",
                "v=5:7:5",
                "v=5:7:6",
                "v=5:7:7",
                "v=5:7:8",
                "v=5:7:9",
                "v=5:8",
                "v=5:8:0",
                "v=5:8:1",
                "v=5:8:2",
                "v=5:8:3",
                "v=5:8:4",
                "v=5:8:5",
                "v=5:8:6",
                "v=5:8:7",
                "v=5:8:8",
                "v=5:8:9",
                "v=5:9",
                "v=5:9:0",
                "v=5:9:1",
                "v=5:9:2",
                "v=5:9:3",
                "v=5:9:4",
                "v=5:9:5",
                "v=5:9:6",
                "v=5:9:7",
                "v=5:9:8",
                "v=5:9:9",
                "v=6",
                "v=6:0",
                "v=6:0:0",
                "v=6:0:1",
                "v=6:0:2",
                "v=6:0:3",
                "v=6:0:4",
                "v=6:0:5",
                "v=6:0:6",
                "v=6:0:7",
                "v=6:0:8",
                "v=6:0:9",
                "v=6:1",
                "v=6:1:0",
                "v=6:1:1",
                "v=6:1:2",
                "v=6:1:3",
                "v=6:1:4",
                "v=6:1:5",
                "v=6:1:6",
                "v=6:1:7",
                "v=6:1:8",
                "v=6:1:9",
                "v=6:2",
                "v=6:2:0",
                "v=6:2:1",
                "v=6:2:2",
                "v=6:2:3",
                "v=6:2:4",
                "v=6:2:5",
                "v=6:2:6",
                "v=6:2:7",
                "v=6:2:8",
                "v=6:2:9",
                "v=6:3",
                "v=6:3:0",
                "v=6:3:1",
                "v=6:3:2",
                "v=6:3:3",
                "v=6:3:4",
                "v=6:3:5",
                "v=6:3:6",
                "v=6:3:7",
                "v=6:3:8",
                "v=6:3:9",
                "v=6:4",
                "v=6:4:0",
                "v=6:4:1",
                "v=6:4:2",
                "v=6:4:3",
                "v=6:4:4",
                "v=6:4:5",
                "v=6:4:6",
                "v=6:4:7",
                "v=6:4:8",
                "v=6:4:9",
                "v=6:5",
                "v=6:5:0",
                "v=6:5:1",
                "v=6:5:2",
                "v=6:5:3",
                "v=6:5:4",
                "v=6:5:5",
                "v=6:5:6",
                "v=6:5:7",
                "v=6:5:8",
                "v=6:5:9",
                "v=6:6",
                "v=6:6:0",
                "v=6:6:1",
                "v=6:6:2",
                "v=6:6:3",
                "v=6:6:4",
                "v=6:6:5",
                "v=6:6:6",
                "v=6:6:7",
                "v=6:6:8",
                "v=6:6:9",
                "v=6:7",
                "v=6:7:0",
                "v=6:7:1",
                "v=6:7:2",
                "v=6:7:3",
                "v=6:7:4",
                "v=6:7:5",
                "v=6:7:6",
                "v=6:7:7",
                "v=6:7:8",
                "v=6:7:9",
                "v=6:8",
                "v=6:8:0",
                "v=6:8:1",
                "v=6:8:2",
                "v=6:8:3",
                "v=6:8:4",
                "v=6:8:5",
                "v=6:8:6",
                "v=6:8:7",
                "v=6:8:8",
                "v=6:8:9",
                "v=6:9",
                "v=6:9:0",
                "v=6:9:1",
                "v=6:9:2",
                "v=6:9:3",
                "v=6:9:4",
                "v=6:9:5",
                "v=6:9:6",
                "v=6:9:7",
                "v=6:9:8",
                "v=6:9:9",
                "v=7",
                "v=7:0",
                "v=7:0:0",
                "v=7:0:1",
                "v=7:0:2",
                "v=7:0:3",
                "v=7:0:4",
                "v=7:0:5",
                "v=7:0:6",
                "v=7:0:7",
                "v=7:0:8",
                "v=7:0:9",
                "v=7:1",
                "v=7:1:0",
                "v=7:1:1",
                "v=7:1:2",
                "v=7:1:3",
                "v=7:1:4",
                "v=7:1:5",
                "v=7:1:6",
                "v=7:1:7",
                "v=7:1:8",
                "v=7:1:9",
                "v=7:2",
                "v=7:2:0",
                "v=7:2:1",
                "v=7:2:2",
                "v=7:2:3",
                "v=7:2:4",
                "v=7:2:5",
                "v=7:2:6",
                "v=7:2:7",
                "v=7:2:8",
                "v=7:2:9",
                "v=7:3",
                "v=7:3:0",
                "v=7:3:1",
                "v=7:3:2",
                "v=7:3:3",
                "v=7:3:4",
                "v=7:3:5",
                "v=7:3:6",
                "v=7:3:7",
                "v=7:3:8",
                "v=7:3:9",
                "v=7:4",
                "v=7:4:0",
                "v=7:4:1",
                "v=7:4:2",
                "v=7:4:3",
                "v=7:4:4",
                "v=7:4:5",
                "v=7:4:6",
                "v=7:4:7",
                "v=7:4:8",
                "v=7:4:9",
                "v=7:5",
                "v=7:5:0",
                "v=7:5:1",
                "v=7:5:2",
                "v=7:5:3",
                "v=7:5:4",
                "v=7:5:5",
                "v=7:5:6",
                "v=7:5:7",
                "v=7:5:8",
                "v=7:5:9",
                "v=7:6",
                "v=7:6:0",
                "v=7:6:1",
                "v=7:6:2",
                "v=7:6:3",
                "v=7:6:4",
                "v=7:6:5",
                "v=7:6:6",
                "v=7:6:7",
                "v=7:6:8",
                "v=7:6:9",
                "v=7:7",
                "v=7:7:0",
                "v=7:7:1",
                "v=7:7:2",
                "v=7:7:3",
                "v=7:7:4",
                "v=7:7:5",
                "v=7:7:6",
                "v=7:7:7",
                "v=7:7:8",
                "v=7:7:9",
                "v=7:8",
                "v=7:8:0",
                "v=7:8:1",
                "v=7:8:2",
                "v=7:8:3",
                "v=7:8:4",
                "v=7:8:5",
                "v=7:8:6",
                "v=7:8:7",
                "v=7:8:8",
                "v=7:8:9",
                "v=7:9",
                "v=7:9:0",
                "v=7:9:1",
                "v=7:9:2",
                "v=7:9:3",
                "v=7:9:4",
                "v=7:9:5",
                "v=7:9:6",
                "v=7:9:7",
                "v=7:9:8",
                "v=7:9:9",
                "v=8",
                "v=8:0",
                "v=8:0:0",
                "v=8:0:1",
                "v=8:0:2",
                "v=8:0:3",
                "v=8:0:4",
                "v=8:0:5",
                "v=8:0:6",
                "v=8:0:7",
                "v=8:0:8",
                "v=8:0:9",
                "v=8:1",
                "v=8:1:0",
                "v=8:1:1",
                "v=8:1:2",
                "v=8:1:3",
                "v=8:1:4",
                "v=8:1:5",
                "v=8:1:6",
                "v=8:1:7",
                "v=8:1:8",
                "v=8:1:9",
                "v=8:2",
                "v=8:2:0",
                "v=8:2:1",
                "v=8:2:2",
                "v=8:2:3",
                "v=8:2:4",
                "v=8:2:5",
                "v=8:2:6",
                "v=8:2:7",
                "v=8:2:8",
                "v=8:2:9",
                "v=8:3",
                "v=8:3:0",
                "v=8:3:1",
                "v=8:3:2",
                "v=8:3:3",
                "v=8:3:4",
                "v=8:3:5",
                "v=8:3:6",
                "v=8:3:7",
                "v=8:3:8",
                "v=8:3:9",
                "v=8:4",
                "v=8:4:0",
                "v=8:4:1",
                "v=8:4:2",
                "v=8:4:3",
                "v=8:4:4",
                "v=8:4:5",
                "v=8:4:6",
                "v=8:4:7",
                "v=8:4:8",
                "v=8:4:9",
                "v=8:5",
                "v=8:5:0",
                "v=8:5:1",
                "v=8:5:2",
                "v=8:5:3",
                "v=8:5:4",
                "v=8:5:5",
                "v=8:5:6",
                "v=8:5:7",
                "v=8:5:8",
                "v=8:5:9",
                "v=8:6",
                "v=8:6:0",
                "v=8:6:1",
                "v=8:6:2",
                "v=8:6:3",
                "v=8:6:4",
                "v=8:6:5",
                "v=8:6:6",
                "v=8:6:7",
                "v=8:6:8",
                "v=8:6:9",
                "v=8:7",
                "v=8:7:0",
                "v=8:7:1",
                "v=8:7:2",
                "v=8:7:3",
                "v=8:7:4",
                "v=8:7:5",
                "v=8:7:6",
                "v=8:7:7",
                "v=8:7:8",
                "v=8:7:9",
                "v=8:8",
                "v=8:8:0",
                "v=8:8:1",
                "v=8:8:2",
                "v=8:8:3",
                "v=8:8:4",
                "v=8:8:5",
                "v=8:8:6",
                "v=8:8:7",
                "v=8:8:8",
                "v=8:8:9",
                "v=8:9",
                "v=8:9:0",
                "v=8:9:1",
                "v=8:9:2",
                "v=8:9:3",
                "v=8:9:4",
                "v=8:9:5",
                "v=8:9:6",
                "v=8:9:7",
                "v=8:9:8",
                "v=8:9:9",
                "v=9",
                "v=9:0",
                "v=9:0:0",
                "v=9:0:1",
                "v=9:0:2",
                "v=9:0:3",
                "v=9:0:4",
                "v=9:0:5",
                "v=9:0:6",
                "v=9:0:7",
                "v=9:0:8",
                "v=9:0:9",
                "v=9:1",
                "v=9:1:0",
                "v=9:1:1",
                "v=9:1:2",
                "v=9:1:3",
                "v=9:1:4",
                "v=9:1:5",
                "v=9:1:6",
                "v=9:1:7",
                "v=9:1:8",
                "v=9:1:9",
                "v=9:2",
                "v=9:2:0",
                "v=9:2:1",
                "v=9:2:2",
                "v=9:2:3",
                "v=9:2:4",
                "v=9:2:5",
                "v=9:2:6",
                "v=9:2:7",
                "v=9:2:8",
                "v=9:2:9",
                "v=9:3",
                "v=9:3:0",
                "v=9:3:1",
                "v=9:3:2",
                "v=9:3:3",
                "v=9:3:4",
                "v=9:3:5",
                "v=9:3:6",
                "v=9:3:7",
                "v=9:3:8",
                "v=9:3:9",
                "v=9:4",
                "v=9:4:0",
                "v=9:4:1",
                "v=9:4:2",
                "v=9:4:3",
                "v=9:4:4",
                "v=9:4:5",
                "v=9:4:6",
                "v=9:4:7",
                "v=9:4:8",
                "v=9:4:9",
                "v=9:5",
                "v=9:5:0",
                "v=9:5:1",
                "v=9:5:2",
                "v=9:5:3",
                "v=9:5:4",
                "v=9:5:5",
                "v=9:5:6",
                "v=9:5:7",
                "v=9:5:8",
                "v=9:5:9",
                "v=9:6",
                "v=9:6:0",
                "v=9:6:1",
                "v=9:6:2",
                "v=9:6:3",
                "v=9:6:4",
                "v=9:6:5",
                "v=9:6:6",
                "v=9:6:7",
                "v=9:6:8",
                "v=9:6:9",
                "v=9:7",
                "v=9:7:0",
                "v=9:7:1",
                "v=9:7:2",
                "v=9:7:3",
                "v=9:7:4",
                "v=9:7:5",
                "v=9:7:6",
                "v=9:7:7",
                "v=9:7:8",
                "v=9:7:9",
                "v=9:8",
                "v=9:8:0",
                "v=9:8:1",
                "v=9:8:2",
                "v=9:8:3",
                "v=9:8:4",
                "v=9:8:5",
                "v=9:8:6",
                "v=9:8:7",
                "v=9:8:8",
                "v=9:8:9",
                "v=9:9",
                "v=9:9:0",
                "v=9:9:1",
                "v=9:9:2",
                "v=9:9:3",
                "v=9:9:4",
                "v=9:9:5",
                "v=9:9:6",
                "v=9:9:7",
                "v=9:9:8",
                "v=9:9:9"
            ],
            "type": "string"
        },
        "QuadLocation": {
            "enum": [
                "0:0",
                "0:1",
                "0:2",
                "0:3",
                "0:4",
                "0:5",
                "0:6",
                "0:7",
                "0:8",
                "0:9",
                "1:0",
                "1:1",
                "1:2",
                "1:3",
                "1:4",
                "1:5",
                "1:6",
                "1:7",
                "1:8",
                "1:9",
                "2:0",
                "2:1",
                "2:2",
                "2:3",
                "2:4",
                "2:5",
                "2:6",
                "2:7",
                "2:8",
                "2:9",
                "3:0",
                "3:1",
                "3:2",
                "3:3",
                "3:4",
                "3:5",
                "3:6",
                "3:7",
                "3:8",
                "3:9",
                "4:0",
                "4:1",
                "4:2",
                "4:3",
                "4:4",
                "4:5",
                "4:6",
                "4:7",
                "4:8",
                "4:9",
                "5:0",
                "5:1",
                "5:2",
                "5:3",
                "5:4",
                "5:5",
                "5:6",
                "5:7",
                "5:8",
                "5:9",
                "6:0",
                "6:1",
                "6:2",
                "6:3",
                "6:4",
                "6:5",
                "6:6",
                "6:7",
                "6:8",
                "6:9",
                "7:0",
                "7:1",
                "7:2",
                "7:3",
                "7:4",
                "7:5",
                "7:6",
                "7:7",
                "7:8",
                "7:9",
                "8:0",
                "8:1",
                "8:2",
                "8:3",
                "8:4",
                "8:5",
                "8:6",
                "8:7",
                "8:8",
                "8:9",
                "9:0",
                "9:1",
                "9:2",
                "9:3",
                "9:4",
                "9:5",
                "9:6",
                "9:7",
                "9:8",
                "9:9",
                "b",
                "bl",
                "br",
                "l",
                "r",
                "t",
                "tl",
                "tr"
            ],
            "type": "string"
        },
        "RepoConfig": {
            "properties": {
                "def": {
                    "type": "string"
                },
                "type": {
                    "type": "string"
                }
            },
            "required": [
                "type"
            ],
            "type": "object"
        },
        "ViewConfig": {
            "properties": {
                "bind": {
                    "type": "string"
                },
                "lang": {
                    "type": "string"
                },
                "location": {
                    "$ref": "#/definitions/QuadLocation"
                },
                "type": {
                    "enum": [
                        "highlight",
                        "pre",
                        "textarea"
                    ],
                    "type": "string"
                }
            },
            "required": [
                "bind",
                "location",
                "type"
            ],
            "type": "object"
        }
    },
    "properties": {
        "editors": {
            "additionalProperties": {
                "$ref": "#/definitions/EditorConfig"
            },
            "type": "object"
        },
        "layout": {
            "$ref": "#/definitions/QuadLayout"
        },
        "outputs": {
            "additionalProperties": {
                "$ref": "#/definitions/OutputConfig"
            },
            "type": "object"
        },
        "pipelineSteps": {
            "additionalProperties": {
                "$ref": "#/definitions/PipelineStepConfig"
            },
            "type": "object"
        },
        "repos": {
            "additionalProperties": {
                "$ref": "#/definitions/RepoConfig"
            },
            "type": "object"
        },
        "views": {
            "additionalProperties": {
                "$ref": "#/definitions/ViewConfig"
            },
            "type": "object"
        }
    },
    "type": "object"
}`
