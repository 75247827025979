import { Observer } from "./types";


export class Repo {
  data: string;
  observers: Observer[];
  constructor(def?: string) {
    this.data = def || '';
    this.observers = [];
  }

  setData(data: string) {
    const prev = this.data;
    this.data = data;
    this.update(prev);
  }

  addObserver(ob: Observer) {
    this.observers = [...this.observers, ob];
  }

  removeObserver(ob: Observer) {
    this.observers = this.observers.filter(x => x !== ob);
  }

  update(prev?: string) {
    this.observers.forEach(ob => {
      ob(this.data, prev);
    });
  }

  deactivate() {
    this.observers = [];
  }
}
