import { PipelineStep } from "./PipelineStep";
import { Repo } from "./Repo";
import { Observer, PlanConfig } from "./types";
import { getFirestore, doc, setDoc } from "firebase/firestore"; 
import { User } from "firebase/auth"
import { PlanDoc } from "../comp/PlanDoc";
export type { Observer }

const PREFIX = 'jsan:'

export async function loadProcessor(planId: string, docId?: string) {
  const [planType, planName] = planId.split(":")
  if (planType === 'b') {
    const response = await window.fetch(`/plans/${planName}.json`)
    console.log('response:', response);
    if (response.ok) {
      const config = await response.json() as PlanConfig
      return await new Processor(config, planId, docId)
    } else {
      alert('NO LOAD PLAN!')
      throw new Error('NO LOAD PLAN!')
    }
  }
  
  throw new Error('NO LOAD'+planType)
}

export class Processor {
  config: PlanConfig
  planId: string
  docId?: string
  repos: {[key: string]: Repo}
  pipelineSteps: {[key: string]: PipelineStep}
  selectedTabs: {[key: string]: string}
  storage: Storage;

  constructor(config: PlanConfig, planId: string, docId?: string) {
    this.config = config
    this.planId = planId
    this.repos = {}
    this.pipelineSteps = {}
    this.selectedTabs = {}

    this.docId = docId === undefined ? 'temporary-doc' : docId
    this.storage = docId === undefined ? window.sessionStorage : window.localStorage

    const loadedState = (() => {
      const str = this.storage.getItem(`${PREFIX}${planId}:${this.docId}`)
      if (str) {
        try {
          const save = JSON.parse(str)
          return save
        } catch (e) {
          console.log('Error parsing')
        }
      }

      return {data:{}}
    })()

    const data = loadedState.data || {}
    this.selectedTabs = loadedState.selectedTabs || {}

    for (const repoName in config.repos) {
      const repo = config.repos[repoName]
      const repoKey = `r:${repoName}`
      const def = data[repoKey] === undefined ? repo.def : data[repoKey]
      this.repos[repoKey] = new Repo(def)
    }

    for (const editorName in config.editors) {
      const editor = config.editors[editorName]
      const editorKey = `e:${editorName}`
      const def = data[editorKey] === undefined ? editor.def : data[editorKey]
      this.repos[editorKey] = new Repo(def)
    }

    
    for (const stepName in config.pipelineSteps) {
      const step = config.pipelineSteps[stepName]
      const stepKey = `p:${stepName}`
      this.pipelineSteps[stepKey] = new PipelineStep(step)
    }

    for (const step in this.pipelineSteps) {
      this.pipelineSteps[step].activate(this)
    }
    for (const repoName in this.repos) {
      this.repos[repoName].update()
    }

    return this
  }

  loadFrom(planDoc: PlanDoc) {
    const reposData = JSON.parse(planDoc.data)
    console.log('this.config.editors:', this.config.editors)
    console.log('reposData:', reposData)
    for (const repoName in this.repos) {
      console.log('repoName:', repoName)
      this.repos[repoName].data = reposData[repoName]
    }
    for (const repoName in this.repos) {
      this.repos[repoName].update()
    }
    this.save()
  }

  setRepoValue(address: string, data: string) {
    const [t] = address.split(':')
    if (t === 'r' || t === 'e') {
      const repo = this.repos[address]
      if (!repo) throw new Error('Tried to bind to repo that does not exist:' + address)
      
      repo.setData(data)
      this.save()
    } else if (t === 'p') {
      // Do nothing
    } else {
      throw new Error('Tried to bind to malformed address:' + address)
    }
  }

  subscribeTo(address: string, ob: Observer): string | undefined {
    const [t] = address.split(':')
    if (t === 'r') {
      const repo = this.repos[address]
      if (!repo) throw new Error('Tried to bind to repo that does not exist:' + address)
      
      repo.addObserver(ob)

      return repo.data
    } else if (t === 'e') {
      const repo = this.repos[address]
      if (!repo) throw new Error('Tried to bind to repo that does not exist:' + address)
      
      repo.addObserver(ob)

      return repo.data
    } else if (t === 'p') {
      const step = this.pipelineSteps[address]
      if (!step) throw new Error('Tried to bind to step that does not exist:' + address)
      
      step.addObserver(ob)
      return step.currentValue
    } else {
      throw new Error('Tried to bind to malformed address:' + address)
    }
  }

  unsubscribeFrom(address: string, ob: Observer) {
    const [t] = address.split(':')
    if (t === 'r' || t === 'e') {
      const repo = this.repos[address]
      if (!repo) throw new Error('Tried to unbind from a repo that does not exist:' + address)
      
      repo.removeObserver(ob)
    }
  }

  saveSelectedTab(location: string, tab: string) {
    this.selectedTabs[location] = tab
    this.save()
  }

  save() {
    const data = Object.entries(this.repos)
      .map(([k,v]) => ({k, v:v.data}))
      .reduce((p, c) => ({...p, [c.k]:c.v}), {})
    const str = this.storage.getItem(`${PREFIX}${this.planId}:${this.docId}`)
    const existing = (() => {
      try {
        if (str) return JSON.parse(str)
      } catch(e) {
        return {}
      }
    })()
    this.storage.setItem(`${PREFIX}${this.planId}:${this.docId}`, JSON.stringify({
      ...existing,
      updated: new Date(),
      data,
      selectedTabs: this.selectedTabs
    }))
  }
  
  async saveToFirebase(user: User | null) {
    if (!user){
      console.error('Not signed in')
      return
    }
    if (!this.docId){
      console.error('No docId')
      return
    }
    const data = Object.entries(this.repos)
      .map(([k,v]) => ({k, v:v.data}))
      .reduce((p, c) => ({...p, [c.k]:c.v}), {})

    const docName = `${user.uid}-${this.planId}:${this.docId}`
    
    const db = getFirestore();
    const asPlanDoc: PlanDoc = {
      public: false,
      owner: user.uid,
      plan: this.planId,
      docId: this.docId,
      createdBy: user.displayName || 'No display name',
      createdByEmail: user.email || 'No email',
      data: JSON.stringify(data)
    }
    await setDoc(doc(db, "docs", docName), asPlanDoc );
    console.log('Saved')
      
  }

  dispose() {
    for (const step in this.pipelineSteps) {
      this.pipelineSteps[step].deactivate(this)
    }
    for (const repoName in this.repos) {
      this.repos[repoName].deactivate()
    }
  }
}