import React, { useRef, useState } from 'react'
import { D, QuadLayout, QuadLocation } from '../processor/types'
import './ResizableQuads.scss'


interface ResiazableQuadsProps {
  layout?: QuadLayout
  fill: (location: QuadLocation) => React.ReactNode
}

export function ResiazableQuads({
  layout,
  fill
}: ResiazableQuadsProps) {
  if (! layout || ['2x1', '1x2', '2x2'].includes(layout)) {
    return <ResiazableQuadsPrimary layout={layout} fill={fill} />
  } else if (layout.startsWith('h=') || layout.startsWith('v=')) {
    return <ResiazableQuadsSlots layout={layout} fill={fill} />
  } else {
    return <div>Unknown layout: {layout}</div>
  }
}
 
export function ResiazableQuadsPrimary({
  layout,
  fill
}: ResiazableQuadsProps) {
  const [hozRatio, setHozRatio] = useState(50)
  const [vrtRatio, setVrtRatio] = useState(50)
  const [vrtBarSelected, setVrtBarSelected] = useState(false)
  const [hozBarSelected, setHozBarSelected] = useState(false)
  const container = useRef<HTMLDivElement>(null)
  const selectedAxis = useRef<'ew'|'ns'|'all'|''>('')
  const mouseMove = useRef((ev: MouseEvent) => {
    if (container.current && selectedAxis.current) {
      const rect = container.current.getBoundingClientRect();

      if (rect.height > window.innerHeight) rect.height = window.innerHeight

      //console.log('rect:', rect)
      if (selectedAxis.current === 'ew' || selectedAxis.current === 'all') {
        // const ratio = (ev.clientX / container.current.clientWidth) * 100
        const ratio = ((ev.clientX - rect.left) / rect.width) * 100
        setHozRatio(ratio)
      }
      if (selectedAxis.current === 'ns' || selectedAxis.current === 'all') {
        // const ratio = (ev.clientY / container.current.clientHeight) * 100
        const ratio = ((ev.clientY - rect.top) / rect.height) * 100
        //console.log('ratio:', ratio)
        //console.log('ratio2:', ratio2)
        setVrtRatio(ratio)
      }
    }
  })

  const startDrag = ( e: React.MouseEvent, bar: 'h' | 'v' | 'b') => {
    e.preventDefault()
    if (bar === 'v') {
      selectedAxis.current = 'ew'
      setVrtBarSelected(true)
    } else if (bar === 'h') {
      selectedAxis.current = 'ns'
      setHozBarSelected(true)
    } else if (bar === 'b') {
      selectedAxis.current = 'all'
      setVrtBarSelected(true)
      setHozBarSelected(true)
    }
    container.current?.addEventListener('mousemove', mouseMove.current, true)
  }

  const stopDrag = () => {
    setVrtBarSelected(false)
    setHozBarSelected(false)
    container.current?.removeEventListener('mousemove', mouseMove.current, true)
  }

  if (! layout || layout === '2x2') {
    const style = {
      gridTemplateColumns: `${hozRatio}fr 8px ${100-hozRatio}fr`,
      gridTemplateRows: `${vrtRatio}fr 8px ${100-vrtRatio}fr`,
    }

    return <div className="ResiazableQuads" style={style} ref={container} onMouseUp={() => stopDrag()}>
      <div className='quad tl'>{fill('tl')}</div>
      <div className={`bar vbar tc ${vrtBarSelected?'selected':''}`} onMouseDown={e => startDrag(e, 'v')}>&nbsp;</div>
      <div className='quad tr'>{fill('tr')}</div>

      <div className={`bar hbar ml ${hozBarSelected?'selected':''}`} onMouseDown={e => startDrag(e, 'h')}>&nbsp;</div>
      <div className={`bar cbar mc ${hozBarSelected || vrtBarSelected?'selected':''}`} onMouseDown={e => startDrag(e, 'b')} >&nbsp;</div>
      <div className={`bar hbar mr ${hozBarSelected?'selected':''}`} onMouseDown={e => startDrag(e, 'h')}>&nbsp;</div>

      <div className='quad bl'>{fill('bl')}</div>
      <div className={`bar vbar bc ${vrtBarSelected?'selected':''}`} onMouseDown={e => startDrag(e, 'v')}>&nbsp;</div>
      <div className='quad br'>{fill('br')}</div>
    </div>
  } else if (layout === '2x1') {
    const style = {
      // gridTemplateColumns: `${hozRatio}fr 8px ${100-hozRatio}fr`,
      gridTemplateRows: `${vrtRatio}fr 8px ${100-vrtRatio}fr`,
    }

    return <div className="ResiazableQuads" style={style} ref={container} onMouseUp={() => stopDrag()}>
      <div className='quad t'>{fill('t')}</div>

      <div className={`bar hbar c ${hozBarSelected?'selected':''}`} onMouseDown={e => startDrag(e, 'h')}>&nbsp;</div>

      <div className='quad b'>{fill('b')}</div>
    </div>
  } else if (layout === '1x2') {
    const style = {
      gridTemplateColumns: `${hozRatio}fr 8px ${100-hozRatio}fr`,
      //gridTemplateRows: `${vrtRatio}fr 8px ${100-vrtRatio}fr`,
    }

    return <div className="ResiazableQuads" style={style} ref={container} onMouseUp={() => stopDrag()}>
      <div className='quad l'>{fill('l')}</div>

      <div className={`bar vbar m ${vrtBarSelected?'selected':''}`} onMouseDown={e => startDrag(e, 'v')}>&nbsp;</div>

      <div className='quad r'>{fill('r')}</div>
    </div>
  } else if (layout.startsWith('h=')) {
    const style = {
      gridTemplateColumns: `${hozRatio}fr 8px ${100-hozRatio}fr`,
      //gridTemplateRows: `${vrtRatio}fr 8px ${100-vrtRatio}fr`,
    }
    // const cols = layout.substring(0, 'h='.length).split(':')

    return <div className="ResiazableQuads" style={style} ref={container} onMouseUp={() => stopDrag()}>

    </div>
  } else {
    return <div>Unknown layout</div>
  }
}

export function ResiazableQuadsSlots({
  layout,
  fill
}: ResiazableQuadsProps) {
  if (!layout) throw new Error('No Layout')
  const hoz = layout.startsWith('h=')

  const slots = hoz ? 
    layout.substring('h='.length).split(':').map(x => parseInt(x, 10)) :
    layout.substring('v='.length).split(':').map(x => parseInt(x, 10))

  const [slotBarSelected, setSlotBarSelected] = useState<boolean[]>([...new Array(slots.length-1)].map(_=>false))
  const [slotRatios, setSlotRatios] = useState<number[]>([...new Array(slots.length)].map((_,i)=>(i+1) * (100/slots.length)))

  const container = useRef<HTMLDivElement>(null)
  const selectedAxis = useRef<number>()

  const mouseMove = useRef((ev: MouseEvent) => {
    if (container.current && selectedAxis.current !== undefined) {
      const bar = selectedAxis.current
      const rect = container.current.getBoundingClientRect();

      const ratio = hoz ?
        ((ev.clientX - rect.left) / rect.width) * 100 : 
        ((ev.clientY - rect.top) / rect.height) * 100

      setSlotRatios(oldSlots => oldSlots.map((s,i) => (i === bar) ? ratio: s))
    }
  })

  const startDrag = ( e: React.MouseEvent, bar: number) => {
    e.preventDefault()
    selectedAxis.current = bar
    const newSlotBarSelected = slotBarSelected.map((_, i) => bar === i)
    setSlotBarSelected(newSlotBarSelected)
    container.current!.addEventListener('mousemove', mouseMove.current, true)
  }

  const stopDrag = () => {
    const newSlotBarSelected = slotBarSelected.map(() => false)
    setSlotBarSelected(newSlotBarSelected)
    selectedAxis.current = undefined
    container.current?.removeEventListener('mousemove', mouseMove.current, true)
  }

  const slotSize = slotRatios.map((s,i,a) => s - (a[i-1] || 0))
  const cols = slotSize.map(s=>`${s}fr`).join(' 8px ')
  const style = {
    gridTemplateColumns: hoz ? cols : undefined,
    gridTemplateRows: !hoz ? cols : undefined,
  }
    
  return <div className="ResiazableQuadsSlots" style={style} ref={container} onMouseUp={() => stopDrag()}>
    <ResiazableSlots spaceCount={slots[0]} fill={(inner) => fill(`1:${inner}` as QuadLocation)} hoz={hoz}/>
    {slots.slice(1).map((slot, i) => {
      const d = `${i+2}` as D
      return <React.Fragment key={i}>
        <div className={`bar ${hoz ? 'vbar' : 'hbar'} tc ${slotBarSelected[i]?'selected':''}`} onMouseDown={e => startDrag(e, i)}>&nbsp;</div>
        <ResiazableSlots spaceCount={slot} fill={(inner) => fill(`${d}:${inner}` as QuadLocation)} hoz={hoz}/>
      </React.Fragment>})
    }
  </div>
}

export function ResiazableSlots({
  spaceCount,
  fill,
  hoz
}: {spaceCount: number, fill: (location: number) => React.ReactNode, hoz: boolean}) {

  const [spaceBarSelected, setSlotBarSelected] = useState<boolean[]>([...new Array(spaceCount-1)].map(_=>false))
  const [spaceRatios, setSlotRatios] = useState<number[]>([...new Array(spaceCount)].map((_,i)=>(i+1) * (100/spaceCount)))

  const container = useRef<HTMLDivElement>(null)
  const selectedAxis = useRef<number>()

  const mouseMove = useRef((ev: MouseEvent) => {
    if (container.current && selectedAxis.current !== undefined) {
      const bar = selectedAxis.current
      const rect = container.current.getBoundingClientRect();
      console.log('rect:', rect)
      const ratio = hoz ? 
        ((ev.clientY - rect.top) / rect.height) * 100 :
        ((ev.clientX - rect.left) / rect.width) * 100
      setSlotRatios(oldSlots => oldSlots.map((s,i) => (i === bar) ? ratio: s))
    }
  })

  const startDrag = ( e: React.MouseEvent, bar: number) => {
    if(container.current) {
      e.preventDefault()
      selectedAxis.current = bar
      const newSlotBarSelected = spaceBarSelected.map((_, i) => bar === i)
      setSlotBarSelected(newSlotBarSelected)
      container.current.addEventListener('mousemove', mouseMove.current, false)
    }
  }

  const stopDrag = () => {
    const newSlotBarSelected = spaceBarSelected.map(() => false)
    setSlotBarSelected(newSlotBarSelected)
    selectedAxis.current = undefined
    container.current?.removeEventListener('mousemove', mouseMove.current, false)
  }

  const slotSize = spaceRatios.map((s,i,a) => s - (a[i-1] || 0))
  const totalBarSize = (slotSize.length-1) * 8
  const barSpace = totalBarSize / slotSize.length
  const template = slotSize.map(s=>`minmax(0, calc(${s}% - ${barSpace}px))`).join(' 8px ')
  const style = {
    gridTemplateRows: hoz ? template : undefined,
    gridTemplateColumns: !hoz ? template : undefined,
  }
  
  const spaces = [...new Array(spaceCount)]

  return <div className="ResiazableSlots" style={style} ref={container} onMouseUp={() => stopDrag()}>
    <div className='quad'>{fill(1)}</div>

    {spaces.slice(1).map((_, i) => {
      return <React.Fragment key={i}>
        <div className={`bar ${hoz ? 'hbar' : 'vbar'} ${spaceBarSelected[i]?'selected':''}`} onMouseDown={e => startDrag(e, i)}>&nbsp;</div>
        <div className='quad'>{fill(i+2)}</div>
        </React.Fragment>})
    }
  </div>

}