
export function bm<T>(obj: {[key: string]: T}): BetterMap<T> {
  return new BetterMap(Object.entries(obj))
}

export class BetterMap<T> extends Map<string, T> {
  map<U>(f: (value: T, key: string, map: BetterMap<T>) => U): BetterMap<U> {
    return new BetterMap<U>([...this].map(([k, v]) => [k, f(v, k, this)]));
  }

}
