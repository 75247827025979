import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';
import { ListDocuments } from './ListDocuments';
import Main from './Main';
import { ListPlans } from './plan-editor/ListPlans';
import { PlanEditorPage } from './plan-editor/PlanEditor';

function App() {

  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path="/plan/:planId/document/:docId" children={<Main />} />
          <Route path="/plan/:planId" exact children={<Main noDoc/>} />
          <Route path="/plan-editor/:planId" children={<PlanEditorPage />} />
          <Route path="/plan-editor" exact children={<ListPlans />} />
          <Route path="/" exact children={<ListDocuments />} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
