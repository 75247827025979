import React, { useEffect, useMemo, useState } from 'react';
import Editor from "@monaco-editor/react";
import { Processor } from './processor';
import { InterfaceConfig, Observer } from './processor/types';
import './EditorPanel.css';

interface EditorPanelProps {name: string, show: boolean, interfaceConfig: InterfaceConfig, processor: Processor, bind: string}

export function EditorPanel({ name, interfaceConfig, show, processor, bind }: EditorPanelProps) {
  const [text, setText] = useState('');
  const setRepoText = useMemo(() => (data: string) => processor.setRepoValue(bind, data), [processor, bind]);

  useEffect(() => {
    const ob: Observer = (value, _ ,error) => {
      if (!error) {
        setText(value);
      }
    };

    const intial = processor.subscribeTo(bind, ob);

    if (intial)
      setText(intial);
    return () => {
      processor.unsubscribeFrom(bind, ob);
    };
  }, [processor, bind]);

  return <div key={name} className="EditorPanel" style={{display: show?'block':'none'}}>
    {(() => {
      if (interfaceConfig.type === 'textarea') {
        return <textarea value={text} onChange={e => setRepoText(e.target.value)} />
      } else if (interfaceConfig.type === 'monaco') {
        return <MonacoEditorPanel interfaceConfig={interfaceConfig} text={text} setRepoText={setRepoText}/>
      } else {
        return <></>;
      }
    })()}
  </div>;
}

type MonacoEditorPanelProps = {text: string, interfaceConfig: InterfaceConfig, setRepoText: (data: string) => void}
function MonacoEditorPanel({text, interfaceConfig, setRepoText}: MonacoEditorPanelProps) {
  const [[line, col], setPos] = useState<[number, number]>([0, 0])
  return <div className="MonacoEditorPanel" >
    <Editor
      height="100%"
      width="100%"
      theme="vs-dark"
      defaultLanguage={interfaceConfig.lang || 'javascript'}
      // defaultValue={text}
      value={text}
      onChange={value => value && setRepoText(value)}
      options={{
        automaticLayout: true,
        tabSize: 2,
        insertSpaces: true
      }}
      onMount={(editor) => {
        editor.onDidChangeCursorPosition(p => setPos([p.position.lineNumber, p.position.column])) 
      }}
    />
    <div className="status">line: {line} col: {col}</div>
  </div>
}