import React, { useReducer } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpload, faDownload, faShareSquare } from '@fortawesome/free-solid-svg-icons'
import { getFirestore, collection, query, where, getDocs } from "firebase/firestore"; 
// import { User } from "firebase/auth"
import { useFirebaseAuth } from "./react-use-firebase-auth";
import './MenuBar.scss';
import { PlanDoc } from './comp/PlanDoc';
import { Processor } from './processor';
import { Link } from 'react-router-dom';

interface MenuBarProps {dispatch: (action: string) => void, processor: Processor}
export function MenuBar({dispatch, processor }: MenuBarProps) {
  const { user, doSignIn, doSignOut } = useFirebaseAuth();

  return <div className="MenuBar">
    <Link to="/"><h1>JSAN Editor</h1></Link>
    <ToolBar dispatchAppAction={dispatch} processor={processor}/>

    {user === null && <button onClick={() => doSignIn()}>Login</button>}
    {typeof user === 'string' && <span>Hello {user} <button onClick={() => doSignOut()}>Logout</button> </span>}
    {user && typeof user === 'object' && <span>Hello {user.displayName} <button onClick={() => doSignOut()}>Logout</button> </span>}
  </div>;
}

interface ToolBarMenuItem {
  title: string
  onClick: () => void
}

interface ToolBarMenuState {
  visible: boolean
  top: number
  left: number
  items: ToolBarMenuItem[],
  title: string
}
type ToolBarMenuAction = {
  type: 'show'
  x: number
  y: number
  title: string
  items: ToolBarMenuItem[]
} | {
  type: 'hide'
}

const initialState: ToolBarMenuState = {
  visible: false,
  top: 0,
  left: 0,
  items: [],
  title: ''
};

function reducer(state: ToolBarMenuState, action:ToolBarMenuAction) {
  switch (action.type) {
    case 'show':
      return {...state, visible: true, left: action.x, top: action.y, title: action.title, items: action.items};
    case 'hide':
      return {...state, visible: false};
    default:
      throw new Error();
  }
}

interface ToolBarProps {dispatchAppAction: (action: string) => void, processor: Processor}
function ToolBar({dispatchAppAction, processor }: ToolBarProps) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const {user} = useFirebaseAuth()

  const showLoadableDocs = async (e: React.MouseEvent) => {
    if (typeof user !== 'object') return
    const db = getFirestore();
    const docsRef = collection(db, "docs");
    
    // , where("plan", "==", "")
    const q = query(docsRef, where("owner", "==", user?.uid), where("plan", "==", processor.planId));
    const querySnapshot = await getDocs(q);
    const loadMenuItems: ToolBarMenuItem[] = querySnapshot.docs.map(queryDoc => {
      const data = queryDoc.data() as PlanDoc
      return {
        title: data.docId,
        onClick: () => {
          console.log('data:', data)
          processor.loadFrom(data)
        }
      }
    })
 

    dispatch({type: 'show', title: 'Load', items: loadMenuItems, x: e.clientX, y: e.clientY})
  }

  return <div className="ToolBar">
    <button className="iconButton" title="Save" onClick={() => dispatchAppAction('save')}><FontAwesomeIcon icon={faUpload} /></button>
    <button className="iconButton" title="Load" onClick={e => showLoadableDocs(e)}><FontAwesomeIcon icon={faDownload} /></button>
    <button className="iconButton" title="Share" ><FontAwesomeIcon icon={faShareSquare} /></button>
    <ToolBarMenu state={state}/> 
  </div>
}


interface ToolBarMenuProps { 
  state: ToolBarMenuState
}
export function ToolBarMenu({state}: ToolBarMenuProps) {

  if (!state.visible) return null

  return <div className="ToolBarMenu" style={{top: `${state.top}px`, left: `${state.left}px`}}>
    <div>{state.title}</div>
    <hr />
    {state.items.map(item => <div key={item.title}>
      <div role="button" style={{cursor: 'pointer'}} onClick={() => item.onClick()} >{item.title}</div>
    </div>)}
  </div>
}