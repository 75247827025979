import React, { useEffect, useRef, useState } from 'react';
import { Processor } from './processor';
import { Observer, OutputConfig } from './processor/types';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { vscDarkPlus as SyntaxHighlighterStyle } from 'react-syntax-highlighter/dist/esm/styles/prism';

import './OutputPanel.scss';

interface EditorPanelProps {name: string, show: boolean, interfaceConfig: OutputConfig, processor: Processor, bind: string}

export function OutputPanel({ name, interfaceConfig, show, processor, bind }: EditorPanelProps) {
  const [text, setText] = useState('');
  const [errorText, setErrorText] = useState<string>();
  const language = interfaceConfig.lang || 'javascript'
  useEffect(() => {
    const ob: Observer = (value, _ ,error) => {
      if (error) {
        setErrorText(error)
      } else {
        setText(value);
        setErrorText(undefined)
      }
    };

    const intial = processor.subscribeTo(bind, ob);

    if (intial)
      setText(intial);
    return () => {
      processor.unsubscribeFrom(bind, ob);
    };
  }, [processor, bind]);

  return <div key={name} className="OutputPanel" style={{display: show?undefined:'none'}}>
    {errorText ? <div className="showError">
      {renderPanel(interfaceConfig.type, text, language)}
      <textarea value={errorText} readOnly onChange={e => {}} />
      </div> : renderPanel(interfaceConfig.type, text, language)
    }
  </div>
}

function renderPanel(type: string, text: string, language: string): JSX.Element {
  const props: PanelProps = {text, language}
  switch(type) {
    case 'textarea': return renderTextArea(props)
    case 'pre': return renderPre(props)
    case 'highlight': return renderHighlight(props)
    case 'html': return <IFrame {...props} />
    default: return <div>No output panel configured for: "{type}"</div>
  }
}

interface PanelProps {
  text: string
  language: string
}

function renderTextArea({text}: PanelProps) {
  return <textarea value={text} readOnly onChange={e => {}} />
}

function renderPre({text}: PanelProps) {
  return <pre>{text}</pre>
}

function renderHighlight({text, language}: PanelProps) {
  if (typeof text !== 'string' || text.length < 1) return <div>Invalid output: {typeof text}</div>
  return <SyntaxHighlighter showLineNumbers language={language} style={SyntaxHighlighterStyle}>{text}</SyntaxHighlighter>
}

function IFrame({text}: PanelProps) {
  const ref = useRef<HTMLIFrameElement>(null)

  useEffect(() => {
    const document = ref?.current?.contentWindow?.document
    if (document) {
      const scripts = [...text.matchAll(/<script[^>]*>([\s\S]*)<\/script>/g)].map((s) => s[1])
      const styles = [...text.matchAll(/<style[^>]*>([\s\S]*)<\/style>/g)].map((s) => s[1])
      const body = [...text.matchAll(/<body[^>]*>([\s\S]*)<\/body>/g)].map((s) => s[1])[0] || ''

      document.body.innerHTML = body
      const style = document.createElement('style')
      style.textContent = styles[0]
      document.body.appendChild(style)
      const script = document.createElement('script')
      script.textContent = `(()=>{${scripts[0]}})()`
      document.body.appendChild(script)
    }
  }, [text])

  return <iframe title="preview" className="output-iframe" ref={ref} />
}

// function ShadowDom({text}: PanelProps) {
//   //return <div dangerouslySetInnerHTML={{__html: text}} />
//   const ref = useRef<HTMLDivElement>(null)
//   const [shadow, setShadow] = useState<ShadowRoot>()

//   useEffect(() => {
//     if (ref.current !== null) {
//       if (ref.current.shadowRoot) {
//         setShadow(ref.current.shadowRoot)
//       } else {
//         const shadow = ref.current.attachShadow({ mode: "open" });
//         setShadow(shadow)
//       }
//     }
//   }, [])

//   useEffect(() => {
//     if (shadow && ref.current) {
//       const scripts: string[] = []
//       const html = text.replace(/<script[^>]*>([\s\S]*)<\/script>/g, (_, p1) => {scripts.push(p1); return ''})

//       const errorHandler = (e: any) => {
//         e.preventDefault();
//         console.error(e)
//       }
//       ref.current.addEventListener('error', errorHandler, true)
//       ref.current.addEventListener('abort', errorHandler, true);
//       try{
//         shadow.innerHTML = html
//         for(const script of scripts){
//           const tag = document.createElement( 'script' )
//           tag.textContent = `(() => {try { ${script}} catch (e) { console.error(e) } })()`
          
//           try {
//             shadow.appendChild(tag)
//           } catch (e) {
//             console.trace(e)
//           }
         
//         }
        
//       } catch (e) {

//       }
//       ref.current.removeEventListener('abort', errorHandler);
//       ref.current.removeEventListener('error', errorHandler)
//     }
//   }, [text, shadow])

//   return <div ref={ref} className="ShadowDom" />
// }

